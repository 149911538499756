/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import * as backend from "bitmask-segwit";
import { Vault } from "bitmask-segwit/bitcoin";
import { NextAddressResponse } from "bitmask-segwit/rgb";

import lib from "src/lib";
import { getConversion, getFeeRate } from "src/Hooks/util";
import { ifaceFungibles } from "src/constants";

import RoundedButton from "src/Components/Buttons/RoundedButton";
import SetFeeRate from "src/Components/Inputs/SetFeeRate";
import Skeleton from "react-loading-skeleton";
import "react-loading-skeleton/dist/skeleton.css";

interface Props {
  walletBalance: string;
  vault: Vault;
  tokensWalletAddress: string;
  udasWalletAddress: string;
  setOpenFundAssetsModal: any;
  wallet: string;
  lnCredentials: {
    login: string;
    password: string;
    refreshToken: string;
    accessToken: string;
  };
  hash: string;
}

const FundAssets = ({ ...props }) => {
  const {
    walletBalance,
    vault,
    wallet,
    lnCredentials,
    hash,
    setOpenFundAssetsModal,
  } = props as Props;
  const [fee, setFeeRate] = useState(0);
  const [optimalFee, setOptimalFee] = useState(0);
  const [cost, setCost] = useState(0);
  const [address, setAddress] = useState("");
  const [loading, setLoading] = useState(false);
  const [conversion, setConversion] = useState(0);
  const [loadingFeeEstimation, setLoadingFeeEstimation] = useState(false);
  const [error, setError] = useState({
    title: "",
    message: "",
  });
  const navigate = useNavigate();

  useEffect(() => {
    (async () => {
      setLoadingFeeEstimation(true);
      const watcherResponse: NextAddressResponse =
        await backend.rgb.nextAddress(vault.private.nostrPrv, ifaceFungibles);
      const rgbAddress = watcherResponse.address;

      setAddress(rgbAddress);

      const feeRate = await getFeeRate();
      const fundVault = await backend.bitcoin.fundVault(
        vault.private.btcDescriptorXprv,
        vault.private.btcChangeDescriptorXprv,
        rgbAddress,
        false,
        feeRate
      );
      setOptimalFee(feeRate);
      setFeeRate(feeRate);
      setCost(fundVault.fundFee || 0);
      setLoadingFeeEstimation(false);
    })();
  }, [conversion]);

  useEffect(() => {
    (async () => {
      const rate = await getConversion();
      setConversion(rate);
    })();
  }, []);

  // TODO: calculate exact amount of sats needed to fund vault

  return (
    <div className="flex flex-col w-full max-w-3xl m-auto sm:px-6 sm:pt-3">
      {Number(walletBalance) >= 4000 ? (
        <div>
          {error.message === "" ? (
            <>
              <h1 className="w-10/12 mx-auto text-xl font-normal text-center md:text-2xl dark:text-white font-integral">
                Funding Assets Wallet
              </h1>
              <p className="w-10/12 mx-auto my-3 text-base font-thin text-left text-black break-words sm:my-5 dark:text-gray-500">
                You are going to send{" "}
                {window.localStorage.getItem("network") !== "bitcoin"
                  ? "tSats"
                  : "Sats"}{" "}
                to your assets wallet to fund it and start receiving and sending
                assets
              </p>
              <div className="flex justify-center mx-auto max-w-10/12 w-10/12">
                {loadingFeeEstimation && Number(walletBalance) >= 4000 ? (
                  <div className="flex flex-col w-full my-4">
                    <p className="mb-3 text-sm font-light text-left text-gray-800 dark:text-gray-500">
                      Set Fee Rate (optimal fee: {props.optimalFee} sats/vByte)
                    </p>
                    <Skeleton
                      baseColor="#1C1C1C"
                      highlightColor="#343434"
                      className="h-10"
                      containerClassName="flex-1"
                    />
                    <Skeleton
                      baseColor="#1C1C1C"
                      highlightColor="#343434"
                      className="h-4"
                      containerClassName="flex-1 mt-5"
                    />
                  </div>
                ) : (
                  <SetFeeRate
                    label="Set Fee Rate"
                    optimalFee={optimalFee}
                    fee={fee}
                    onFeeChange={(amount) => setFeeRate(amount)}
                    onFeeRefresh={(amount) => setOptimalFee(amount)}
                  />
                )}
              </div>
              <div className="flex-col mx-auto mt-6 flex-grow-default max-w-10/12">
                <div className="flex flex-nowrap">
                  <p className="mb-1 text-base font-semibold text-gray-700 sm:text-lg dark:text-gray-400">
                    Total
                  </p>
                  <p className="my-auto ml-auto text-lg font-medium text-right text-black truncate dark:text-white">
                    {loadingFeeEstimation && Number(walletBalance) >= 4000 ? (
                      <Skeleton
                        baseColor="#1C1C1C"
                        highlightColor="#343434"
                        className="max-w-40 min-w-40"
                      />
                    ) : (
                      <>
                        {cost.toLocaleString()}{" "}
                        {window.localStorage.getItem("network") !== "bitcoin"
                          ? "tSats"
                          : "Sats"}{" "}
                      </>
                    )}
                  </p>
                </div>
                <div className="flex m-auto flex-nowrap">
                  <p className="mt-1 text-base font-thin text-gray-800 dark:text-gray-500">
                    Amount + Fee
                  </p>
                  <p className="my-auto ml-auto text-base font-thin text-right text-gray-800 truncate dark:text-gray-500">
                    {loadingFeeEstimation && Number(walletBalance) >= 4000 ? (
                      <Skeleton
                        baseColor="#1C1C1C"
                        highlightColor="#343434"
                        className="max-w-40 min-w-40"
                      />
                    ) : (
                      <>
                        $
                        {((cost / 100_000_000) * conversion).toLocaleString(
                          undefined,
                          {
                            minimumFractionDigits: 3,
                          }
                        )}{" "}
                        USD
                      </>
                    )}
                  </p>
                </div>
              </div>
              <div className="flex w-10/12 mx-auto my-3 flex-nowrap space-x-6">
                <RoundedButton
                  className="inline-flex justify-center w-full px-4 mt-3 text-base font-medium text-white bg-darkmode-700 rounded-md shadow-sm"
                  onClick={() => {
                    setOpenFundAssetsModal(false);
                  }}
                  disabled={
                    loadingFeeEstimation && Number(walletBalance) >= 4000
                  }
                >
                  Cancel
                </RoundedButton>
                <RoundedButton
                  className="inline-flex justify-center w-full px-4 mt-3 text-base font-medium text-black bg-yellow-500 rounded-md shadow-sm"
                  onClick={async () => {
                    let funded = false;
                    try {
                      if (fee > optimalFee * 2 || fee < 1) {
                        setError({
                          title: "An error occurred while funding asset wallet",
                          message: `Fee rate is set out of range, allowed range is between 1 and ${
                            optimalFee * 2
                          } sats/vByte.`,
                        });
                      } else {
                        setLoading(true);
                        console.debug(`fund vault address: ${address}`);

                        const fundVault = await backend.bitcoin.fundVault(
                          vault.private.btcDescriptorXprv,
                          vault.private.btcChangeDescriptorXprv,
                          address,
                          true,
                          fee !== 0 ? fee : optimalFee
                        );
                        funded = fundVault.isFunded;
                        lib.storageSet({ funded: true }).then(() => {
                          setOpenFundAssetsModal(false);
                          // Should be unnecessary -H
                          // if (isExtension) {
                          //   chrome.runtime.sendMessage({
                          //     call: "reload_script_and_tabs",
                          //   });
                          // }
                          navigate("/success", {
                            state: {
                              wallet,
                              vault,
                              lnCredentials,
                              hash,
                              transaction: {
                                assetType: "Funded",
                                id: fundVault.fundTxid,
                              },
                            },
                          });
                        });
                      }
                    } catch (err: any) {
                      console.error(
                        "An error occurred while funding asset wallet:",
                        err
                      );
                      let message =
                        "Make sure you have enough balance and is confirmed and try again afeter a while and refresh the wallet.";
                      if (funded)
                        message =
                          "Funding transaction is already broadcasted, please wait and refresh the wallet.";
                      setLoading(false);
                      setError({
                        title: "An error occurred while funding asset wallet",
                        message,
                      });
                    }
                  }}
                  loading={loading}
                  disabled={
                    loadingFeeEstimation && Number(walletBalance) >= 4000
                  }
                >
                  Okay
                </RoundedButton>
              </div>
            </>
          ) : (
            <div className="w-11/12 mx-auto my-3 sm:w-10/12">
              <h1 className="w-10/12 mx-auto text-lg font-normal text-center md:text-xl dark:text-white font-integral">
                {error.title}
              </h1>
              <p className="w-11/12 mx-auto my-3 text-base font-light text-gray-800 break-words dark:text-gray-300">
                {error.message}
              </p>
              <RoundedButton
                className="inline-flex justify-center w-full max-w-xs px-4 mt-3 text-base font-medium text-black bg-yellow-500 rounded-md shadow-sm"
                onClick={() => {
                  setOpenFundAssetsModal(false);
                }}
              >
                Okay
              </RoundedButton>
            </div>
          )}
        </div>
      ) : (
        <>
          <h1 className="w-10/12 mx-auto text-lg font-medium text-center text-black md:w-11/12 md:text-xl dark:text-white">
            Wallet Balance is too Low to Fund Assets Vault
          </h1>
          <p className="w-10/12 mx-auto my-6 text-base font-thin text-black break-words md:w-11/12 dark:text-gray-300">
            Not enough{" "}
            {window.localStorage.getItem("network") !== "bitcoin"
              ? "tSats"
              : "Sats"}{" "}
            to create the required UTXOs. RGB{" "}
            {window.localStorage.getItem("network") !== "bitcoin"
              ? "testnet"
              : ""}{" "}
            transactions currently require on-chain{" "}
            {window.localStorage.getItem("network") !== "bitcoin"
              ? "tSats"
              : "Sats"}{" "}
            to receive.
          </p>
          <p className="w-10/12 mx-auto mb-3 text-base font-thin text-center text-black break-words sm:mb-5 dark:text-gray-300">
            This costs {cost}{" "}
            {window.localStorage.getItem("network") !== "bitcoin"
              ? "tSats"
              : "Sats"}
            , or roughly ${((cost / 100_000_000) * conversion).toFixed(2)} worth
            of bitcoin.
          </p>
          <RoundedButton
            className="inline-flex justify-center w-10/12 px-4 mt-1.5 mb-3 mx-auto text-base font-medium text-black bg-yellow-500 rounded-md shadow-sm max-w-xs"
            onClick={() => {
              setOpenFundAssetsModal(false);
            }}
          >
            Okay
          </RoundedButton>
        </>
      )}
    </div>
  );
};

export default FundAssets;
