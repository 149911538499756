/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable no-unused-vars */
import React, { useEffect, useState } from "react";
import { Vault } from "bitmask-segwit/bitcoin";

import CreatePassword from "src/Components/WalletSetup/CreatePassword";
import Card1 from "src/Components/WalletSetup/Card1";
import Card2 from "src/Components/WalletSetup/Card2";
import Success from "src/Components/WalletSetup/Success";
import CreateUsername from "src/Components/WalletSetup/CreateUsername";

const WalletSetup = ({ ...props }) => {
  const [step, setStep] = useState("start");
  const [mnemonic, setMnemonic] = useState([]);
  const [vault, setVault] = useState<Vault>();
  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");

  const [steps, setSteps] = useState([
    { name: "Set Password", href: "#", status: "current" },
    { name: "Create Username", href: "#", status: "upcoming" },
    { name: "Secure Wallet", href: "#", status: "upcoming" },
  ]);

  const handleChange = (arg) => {
    setStep(arg);
  };

  const alertUser = (e) => {
    e.preventDefault();
    e.returnValue = "";
  };

  useEffect(() => {
    window.addEventListener("beforeunload", alertUser);
    return () => {
      window.removeEventListener("beforeunload", alertUser);
    };
  }, []);

  const changeView = (param) => {
    switch (param) {
      case "start":
        return (
          <CreatePassword
            username={username}
            setUsername={setUsername}
            password={password}
            confirmPassword={confirmPassword}
            setPassword={setPassword}
            setConfirmPassword={setConfirmPassword}
            steps={steps}
            setSteps={setSteps}
            setMnemonic={setMnemonic}
            setVault={setVault}
            handleChange={handleChange}
          />
        );
      case "createUsername":
        return (
          <CreateUsername
            username={username}
            setUsername={setUsername}
            steps={steps}
            setSteps={setSteps}
            vault={vault}
            mnemonic={mnemonic}
            handleChange={handleChange}
          />
        );
      case "secureWallet":
        return (
          <Card1
            {...props}
            steps={steps}
            setSteps={setSteps}
            handleChange={handleChange}
            mnemonic={mnemonic}
          />
        );
      case "confirmPhrase":
        return (
          <Card2
            {...props}
            steps={steps}
            setSteps={setSteps}
            handleChange={handleChange}
            mnemonic={mnemonic}
          />
        );
      case "complete":
        return (
          <Success {...props} handleChange={handleChange} password={password} />
        );
      default:
        return (
          <Card1
            {...props}
            steps={steps}
            setSteps={setSteps}
            handleChange={handleChange}
            mnemonic={mnemonic}
          />
        );
    }
  };

  return (
    <div className="flex flex-col justify-between w-11/12 h-full max-w-3xl m-auto overflow-hidden md:w-full">
      <div className="flex justify-center my-auto">{changeView(step)}</div>
    </div>
  );
};

export default WalletSetup;
