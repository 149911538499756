/* eslint-disable no-unused-vars */
/* eslint-disable arrow-body-style */
/* eslint-disable jsx-a11y/control-has-associated-label */
import React from "react";

import { classNames, getFeeRate } from "src/Hooks/util";
import Refresh from "../Icons/Refresh";
import TextFieldWithButton from "./TextFieldWithButton";

interface Props {
  className?: string;
  darkest?: boolean;
  label: string;
  optimalFee: number;
  fee: number;
  onFeeChange: (v: number) => void;
  onFeeRefresh: (v: number) => void;
}

const SetFeeRate: React.FC<Props> = ({ className = "", ...props }) => {
  const handleRefreshFeeRate = async () => {
    const feeRate = await getFeeRate();
    props.onFeeChange(feeRate);
    props.onFeeRefresh(feeRate);
  };

  return (
    <div className="flex flex-col justify-center w-full my-4 text-black dark:text-white">
      <p className="mb-1 text-sm font-light text-left text-gray-800 dark:text-gray-500">
        {props.label} (optimal fee: {props.optimalFee} sats/vByte)
      </p>
      <TextFieldWithButton
        type="number"
        min="1"
        max={props.optimalFee * 2}
        handleOnInputChange={(value) => props.onFeeChange(parseFloat(value))}
        inputValue={props.fee}
        handleOnCLick={() => handleRefreshFeeRate()}
        buttonName="refresh"
        className=""
      />
      <input
        type="range"
        value={props.fee}
        min={props.optimalFee > 2 ? 1 : 0.1}
        max={props.optimalFee * 2}
        step={props.optimalFee > 2 ? 1 : 0.1}
        onChange={(e) => props.onFeeChange(parseFloat(e.target.value))}
        className="h-6 mt-6 bg-darkmode-900"
      />
      <div className="flex justify-between w-full px-2 text-xs text-gray-500">
        <span>min</span>
        <span>optimal fee: {props.optimalFee}</span>
        <span>max</span>
      </div>
    </div>
  );
};

export default SetFeeRate;
