/* eslint-disable no-unused-vars */
/* eslint-disable no-case-declarations */
/* eslint-disable no-case-declarations */
/* global chrome */
import React, { useState, useEffect } from "react";
import { useLocation } from "react-router-dom";
import { v4 as uuidv4 } from "uuid";
import * as backend from "bitmask-segwit";
import { Activity } from "bitmask-segwit/bitcoin";
import {
  IssueRequest,
  RgbOfferRequest,
  RgbSwap,
  PublishPsbtRequest,
  RgbBidResponse,
  RgbSwapValidationResponse,
  RgbCancelOffersRequest,
  RgbCancelBidsRequest,
  NextUtxoResponse,
} from "bitmask-segwit/rgb";

import RoundedButton from "src/Components/Buttons/RoundedButton";
import { Location } from "src/types";
import { tapretPrefix } from "src/constants";
import { getFeeRate } from "src/Hooks/util";
import ErrorModal from "src/Components/Modals/Error";

const MarketplaceActions = () => {
  const [loading, setLoading] = useState(false);
  const location = useLocation() as Location;
  const [open, setOpen] = useState(false);
  const [error, setError] = useState({
    title: "",
    message: "",
  });
  const { message, tabid, pubkeyHash, id, uid, marketplaceAction, vault } =
    location.state;
  // TODO: add analytics

  useEffect(() => {
    window.onbeforeunload = () => {
      chrome.runtime.sendMessage({
        call: "focus_last_active_tab",
      });
      chrome.runtime.sendMessage({
        call: "reload_script_and_tabs",
      });
    };
  }, []);

  const handleSubmit = async () => {
    try {
      if (marketplaceAction.call === "issue_uda") {
        setLoading(true);

        // Update all RGBWallet States
        await backend.rgb.verifyTransfers(vault.private.nostrPrv);

        const result = await chrome.storage.local.get(["udaData"]);
        const avalaibleUtxo: NextUtxoResponse = await backend.rgb.nextUtxo(
          vault.private.nostrPrv,
          "rgb21"
        );

        const isFunded = !!avalaibleUtxo.utxo;
        if (isFunded) {
          const currentUtxoUdas = avalaibleUtxo.utxo;
          const newUdaData: IssueRequest = {
            ticker: result.udaData.uda.ticker,
            name: result.udaData.uda.name,
            description: result.udaData.uda.description,
            supply: "1",
            precision: 0,
            seal: `${tapretPrefix}:${currentUtxoUdas}`,
            iface: result.udaData.uda.iface,
            meta: result.udaData.uda.meta,
            chain: "bitcoin",
          };
          const issueResponse = JSON.parse(
            await backend.issue_contract_proxy(
              vault.private.nostrPrv,
              newUdaData
            )
          ).data;
          if (issueResponse) {
            let swapResponse = {};
            if (
              result.udaData.option !== "Unlisted" &&
              result.udaData.bitcoinPrice &&
              result.udaData.bitcoinPrice >= 0
            ) {
              const rgbOfferRequest: RgbOfferRequest = {
                strategy:
                  result.udaData.option === "Auction"
                    ? RgbSwap.auction
                    : RgbSwap.p2p,
                offers: [
                  {
                    contractId: issueResponse.contractId,
                    iface: issueResponse.iface,
                    assetAmount: "1",
                    bitcoinPrice: BigInt(result.udaData.bitcoinPrice),
                  },
                ],
              };
              const secretKeys: string[] = [
                vault.private.rgbDescriptorXprv,
                vault.private.btcChangeDescriptorXprv,
              ];
              swapResponse =
                result.udaData.option === "Auction"
                  ? JSON.parse(
                      await backend.create_auction_offer(
                        vault.private.nostrPrv,
                        rgbOfferRequest,
                        secretKeys
                      )
                    ).data
                  : JSON.parse(
                      await backend.create_p2p_offer(
                        vault.private.nostrPrv,
                        rgbOfferRequest,
                        secretKeys
                      )
                    ).data;
            }
            chrome.tabs.sendMessage(
              tabid,
              {
                authenticated: "yes",
                answer: message,
                pubkeyHash,
                issueResponse: JSON.stringify(issueResponse),
                swapResponse: JSON.stringify(swapResponse),
                id,
              },
              (response) => {
                chrome.runtime.sendMessage({
                  call: "focus_last_active_tab",
                });
                // .then(() => window.close());
              }
            );
          } else {
            chrome.tabs.sendMessage(
              tabid,
              {
                authenticated: "yes",
                answer: message,
                pubkeyHash,
                id,
                error: "yes",
              },
              (response) => {
                chrome.runtime.sendMessage({
                  call: "focus_last_active_tab",
                });
                // .then(() => window.close());
              }
            );
          }
        } else {
          chrome.tabs.sendMessage(
            tabid,
            {
              authenticated: "yes",
              answer: message,
              pubkeyHash,
              id,
              error: "yes",
              errorTitle: "Vault not funded",
              errorMessage:
                "You must fund the vault before issuing a UDA. Please fund the vault and try again.",
            },
            (response) => {
              chrome.runtime
                .sendMessage({
                  call: "focus_last_active_tab",
                })
                .then(() => window.close());
            }
          );
        }
      } else if (marketplaceAction.call === "swap_offer") {
        setLoading(true);
        const { offerData } = await chrome.storage.local.get(["offerData"]);

        // Update all RGBWallet States
        await backend.rgb.verifyTransfers(vault.private.nostrPrv);

        let rgbOfferRequest: RgbOfferRequest;
        const secretKeys: string[] = [
          vault.private.rgbDescriptorXprv,
          vault.private.btcDescriptorXprv,
          vault.private.btcChangeDescriptorXprv,
        ];

        let checkSwapResponse: RgbSwapValidationResponse = {
          valid: false,
          message: "",
          warnings: [],
          failures: [],
        };
        let swapResponse = {};
        switch (offerData.option) {
          case "Auction":
            rgbOfferRequest = {
              strategy: RgbSwap.auction,
              offers: [
                {
                  contractId: offerData.contractId,
                  iface: offerData.iface,
                  assetAmount: "1",
                  bitcoinPrice: BigInt(offerData.bitcoinPrice),
                },
              ],
            };
            swapResponse = JSON.parse(
              await backend.create_auction_offer(
                vault.private.nostrPrv,
                rgbOfferRequest,
                secretKeys
              )
            ).data;
            break;
          case "Airdrop":
            rgbOfferRequest = {
              strategy: RgbSwap.airdrop,
              offers: [
                {
                  contractId: offerData.contractId,
                  iface: offerData.iface,
                  assetAmount: "1",
                  bitcoinPrice: BigInt(offerData.bitcoinPrice),
                },
              ],
            };
            swapResponse = JSON.parse(
              await backend.create_airdrop_offer(
                vault.private.nostrPrv,
                rgbOfferRequest,
                secretKeys,
                BigInt(1), // TODO: calculate fee rates
                "1" // TODO: put max claims
              )
            ).data;
            break;
          case "HotSwap":
            rgbOfferRequest = {
              strategy: RgbSwap.hotSwap,
              offers: [
                {
                  contractId: offerData.contractId,
                  iface: offerData.iface,
                  assetAmount: "1",
                  bitcoinPrice: BigInt(offerData.bitcoinPrice),
                },
              ],
            };
            swapResponse = JSON.parse(
              await backend.create_hotswap_offer(
                vault.private.nostrPrv,
                rgbOfferRequest
              )
            ).data;
            break;
          default:
            rgbOfferRequest = {
              strategy: RgbSwap.p2p,
              offers: [
                {
                  contractId: offerData.contractId,
                  iface: offerData.iface,
                  assetAmount: "1",
                  bitcoinPrice: BigInt(offerData.bitcoinPrice),
                },
              ],
            };
            checkSwapResponse = JSON.parse(
              await backend.can_create_p2p_offer(
                vault.private.nostrPrv,
                rgbOfferRequest,
                secretKeys
              )
            );

            if (checkSwapResponse.valid) {
              swapResponse = JSON.parse(
                await backend.create_p2p_offer(
                  vault.private.nostrPrv,
                  rgbOfferRequest,
                  secretKeys
                )
              ).data;
            }

            break;
        }

        chrome.tabs.sendMessage(
          tabid,
          {
            authenticated: "yes",
            answer: message,
            pubkeyHash,
            swapResponse: JSON.stringify(swapResponse),
            checkSwapResponse: JSON.stringify(checkSwapResponse),
            id,
          },
          (response) => {
            chrome.runtime
              .sendMessage({
                call: "focus_last_active_tab",
              })
              .then(() => window.close());
          }
        );
      } else if (marketplaceAction.call === "cancel_swap_offer") {
        setLoading(true);
        const { offerCancelData } = await chrome.storage.local.get([
          "offerCancelData",
        ]);

        // Update all RGBWallet States
        await backend.rgb.verifyTransfers(vault.private.nostrPrv);

        const option = "P2P";
        const cancelOfferReq: RgbCancelOffersRequest = offerCancelData;
        let cancelOfferRes = {};

        if (option) {
          cancelOfferRes = JSON.parse(
            await backend.cancel_p2p_offer(
              vault.private.nostrPrv,
              cancelOfferReq
            )
          );
        }

        chrome.tabs.sendMessage(
          tabid,
          {
            authenticated: "yes",
            answer: message,
            pubkeyHash,
            cancelSwapResponse: JSON.stringify(cancelOfferRes),
            id,
          },
          (_) => {
            chrome.runtime
              .sendMessage({
                call: "focus_last_active_tab",
              })
              .then(() => window.close());
          }
        );
      } else if (marketplaceAction.call === "swap_bid") {
        setLoading(true);
        const { bidData } = await chrome.storage.local.get(["bidData"]);

        // Update all RGBWallet States
        await backend.rgb.verifyTransfers(vault.private.nostrPrv);

        const imported = await backend.import_contract(
          vault.private.nostrPrv,
          bidData.contract
        );

        console.debug("Contract Imported", imported);

        const feeRate = await getFeeRate();
        let fee = feeRate * 0.01 * bidData.bitcoinPrice;
        fee = Math.round(fee);
        const secretKeys: string[] = [
          vault.private.rgbDescriptorXprv,
          vault.private.btcDescriptorXprv,
          vault.private.btcChangeDescriptorXprv,
        ];

        const chainFee = { value: BigInt(1000) };
        let checkSwapResponse: RgbSwapValidationResponse = {
          valid: false,
          message: "",
          warnings: [],
          failures: [],
        };
        let swapResponse: RgbBidResponse | undefined;
        switch (bidData.option) {
          case "Auction":
            swapResponse = JSON.parse(
              await backend.create_auction_bid(
                vault.private.nostrPrv,
                {
                  offerId: bidData.offerId,
                  assetAmount: "1",
                  fee: chainFee,
                  bitcoinPrice: bidData.bitcoinPrice,
                  bundleId: bidData.bundleId,
                },
                secretKeys
              )
            ).data;
            break;
          case "Airdrop":
            swapResponse = JSON.parse(
              await backend.create_airdrop_bid(vault.private.nostrPrv, {
                offerId: bidData.offerId,
                assetAmount: "1",
                fee: chainFee,
                bitcoinPrice: bidData.bitcoinPrice,
              })
            ).data;
            break;
          case "HotSwap":
            swapResponse = JSON.parse(
              await backend.create_hotswap_bid(
                vault.private.nostrPrv,
                {
                  offerId: bidData.offerId,
                  assetAmount: "1",
                  fee: chainFee,
                  bitcoinPrice: bidData.bitcoinPrice,
                },
                secretKeys
              )
            ).data;
            break;
          default:
            const checkSwap = await backend.can_create_p2p_bid(
              vault.private.nostrPrv,
              {
                offerId: bidData.offerId,
                assetAmount: "1",
                fee: chainFee,
                bitcoinPrice: bidData.bitcoinPrice,
              },
              secretKeys
            );
            checkSwapResponse = JSON.parse(checkSwap);

            if (checkSwapResponse.valid) {
              swapResponse = JSON.parse(
                await backend.create_p2p_bid(
                  vault.private.nostrPrv,
                  {
                    offerId: bidData.offerId,
                    assetAmount: "1",
                    fee: chainFee,
                    bitcoinPrice: bidData.bitcoinPrice,
                  },
                  secretKeys
                )
              ).data;
            } else {
              swapResponse = undefined;
            }
            break;
        }

        if (swapResponse !== undefined && swapResponse.swapPsbt) {
          chrome.tabs.sendMessage(
            tabid,
            {
              authenticated: "yes",
              answer: message,
              pubkeyHash,
              error: "no",
              swapResponse: JSON.stringify(swapResponse),
              checkSwapResponse: JSON.stringify(checkSwapResponse),
              id,
            },
            (response) => {
              chrome.runtime
                .sendMessage({
                  call: "focus_last_active_tab",
                })
                .then(() => window.close());
            }
          );
        } else {
          // TODO: proper error message
          setError({
            title: `Error at handling swap`,
            message: "Error at handling swap",
          });
          setOpen(true);
          chrome.tabs.sendMessage(
            tabid,
            {
              authenticated: "yes",
              answer: message,
              pubkeyHash,
              error: "yes",
              checkSwapResponse: JSON.stringify(checkSwapResponse),
              id,
            },
            (response) => {
              chrome.runtime
                .sendMessage({
                  call: "focus_last_active_tab",
                })
                .then(() => window.close());
            }
          );
        }
      } else if (marketplaceAction.call === "cancel_swap_bid") {
        setLoading(true);
        const { bidCancelData } = await chrome.storage.local.get([
          "bidCancelData",
        ]);

        // Update all RGBWallet States
        await backend.rgb.verifyTransfers(vault.private.nostrPrv);

        const option = "P2P";
        const cancelBidReq: RgbCancelBidsRequest = bidCancelData;
        let cancelBidRes = {};

        if (option) {
          cancelBidRes = JSON.parse(
            await backend.cancel_p2p_bid(vault.private.nostrPrv, cancelBidReq)
          );
        }

        chrome.tabs.sendMessage(
          tabid,
          {
            authenticated: "yes",
            answer: message,
            pubkeyHash,
            cancelSwapResponse: JSON.stringify(cancelBidRes),
            id,
          },
          (_) => {
            chrome.runtime
              .sendMessage({
                call: "focus_last_active_tab",
              })
              .then(() => window.close());
          }
        );
      } else if (marketplaceAction.call === "get_username") {
        const username = localStorage.getItem("username");
        chrome.tabs.sendMessage(
          tabid,
          {
            authenticated: "yes",
            answer: message,
            pubkeyHash,
            id,
            username,
          },
          (response) => {
            chrome.runtime
              .sendMessage({
                call: "focus_last_active_tab",
              })
              .then(() => window.close());
          }
        );
      } else {
        chrome.tabs.sendMessage(
          tabid,
          { authenticated: "yes", answer: message, pubkeyHash, id },
          (response) => {
            chrome.runtime
              .sendMessage({
                call: "focus_last_active_tab",
              })
              .then(() => window.close());
          }
        );
      }
    } catch (err) {
      chrome.tabs.sendMessage(
        tabid,
        {
          authenticated: "yes",
          answer: message,
          pubkeyHash,
          id,
          error: "yes",
          errorTitle: "Server Error",
          errorMessage: err,
        },
        (response) => {
          chrome.runtime
            .sendMessage({
              call: "focus_last_active_tab",
            })
            .then(() => window.close());
        }
      );
    }
  };

  return (
    <>
      <div className="flex justify-center w-11/12 mx-auto overflow-y-auto h-page 4xl:w-4/12 3xl:w-5/12 2xl:w-6/12 xl:w-7/12 lg:w-8/12">
        <div className="flex flex-col justify-center w-full h-full mx-auto content-evenly">
          <h1 className="mt-12 mb-20 text-xl font-normal text-center text-black xs:text-2xl md:text-3xl dark:text-white">
            {marketplaceAction.title}
          </h1>
          <div className="flex flex-col justify-center w-full m-auto">
            <div className="flex flex-row w-full m-auto">
              <div className="flex flex-col justify-center w-full m-auto">
                <img
                  src={
                    ![undefined, "undefined"].includes(uid)
                      ? `${process.env.REACT_APP_BUCKET_URI}/${uid}-profile`
                      : "/images/defaultAvatar.png"
                  }
                  className="order-2 w-12 h-12 m-auto rounded-full"
                  alt="profile, or collection"
                />
                <div className="pt-1 pb-6 pl-2 text-base font-light text-center dark:text-gray-300">
                  DIBA account
                </div>
              </div>
              <div className="mb-6 border-gray-500 border-dashed border-b-1 w-120" />
              <div className="flex flex-col justify-center w-full m-auto">
                <img
                  src="/images/logo_128.png"
                  className="order-2 w-12 h-12 m-auto rounded-full"
                  alt="profile, or collection"
                />
                <div className="pt-1 pb-6 pl-2 text-base font-light text-center dark:text-gray-300">
                  BitMask Wallet
                </div>
              </div>
            </div>
            <div className="pl-2 text-base font-light text-center border-gray-800 pb-9 mt-9 xs:text-lg pt-9 dark:text-gray-300 border-t-1">
              {marketplaceAction.description &&
              marketplaceAction.description.includes("diba.io") ? (
                <p>
                  {marketplaceAction.description.replace("diba.io", "")}{" "}
                  <a
                    href="https://diba.io"
                    target="_blank"
                    rel="noreferrer"
                    className="underline"
                  >
                    diba.io
                  </a>
                  .
                </p>
              ) : (
                marketplaceAction.description
              )}
            </div>
          </div>
          <div className="w-10/12 m-auto mb-12 xs:w-9/12 grid grid-cols-2 gap-6 sm:mb-20">
            <RoundedButton
              className="text-base dark:bg-darkmode-700 text-white"
              onClick={() => {
                chrome.tabs.sendMessage(
                  tabid,
                  { authenticated: "no", answer: message, pubkeyHash, id },
                  (response) => {
                    chrome.runtime
                      .sendMessage({
                        call: "focus_last_active_tab",
                      })
                      .then(() => window.close());
                  }
                );
                chrome.runtime
                  .sendMessage({
                    call: "focus_last_active_tab",
                  })
                  .then(() => window.close());
              }}
            >
              Cancel
            </RoundedButton>
            <RoundedButton
              className="text-base bg-yellow-500 text-newdarkmode-900"
              onClick={() => handleSubmit()}
              loading={loading}
            >
              Authorize
            </RoundedButton>
          </div>
        </div>
      </div>
      <ErrorModal
        open={open}
        setOpen={setOpen}
        message={error.message}
        title={error.title}
      />
    </>
  );
};

export default MarketplaceActions;
