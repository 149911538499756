/* eslint-disable no-unused-vars */
/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable no-nested-ternary */
/* global chrome */
import React, { useState, useEffect } from "react";
import { useNavigate, useLocation } from "react-router-dom";

import FullWidthTextField from "src/Components/Inputs/FullWidthTextField";
import PageWrapper from "src/Components/Layout/Wrappers/PageWrapper";
import ErrorModal from "src/Components/Modals/Error";
import CopiedToClipboard from "src/Components/Modals/CopiedToClipboard";
import Copy from "src/Components/Icons/Copy";
import RoundedButton from "src/Components/Buttons/RoundedButton";
import { Location } from "src/types";
import { tapretPrefix, ifaceFungibles } from "src/constants";
import * as backend from "bitmask-segwit";
import lib, { isExtension } from "src/lib";
import LeftLabelToggle from "src/Components/Inputs/LeftLabelToggle";
import {
  getConversion,
  getFeeRate,
  getLargeNumber,
  lndhubError,
} from "src/Hooks/util";
import { IssueRequest, NextUtxoResponse } from "bitmask-segwit/rgb";
import { DISABLE_LN, LNDHUBX } from "bitmask-segwit/constants";
import { LnWalletData } from "bitmask-segwit/lightning";
import CheckCircle from "src/Components/Icons/CheckCircle";
import CopyButton from "src/Components/Buttons/CopyButton";

const CreateAsset = () => {
  const [open, setOpen] = useState(false);
  const [error, setError] = useState({
    title: "",
    message: "",
  });
  const [loading, setLoading] = useState(false);
  const [publicAsset, setPublicAsset] = useState(false);
  const [issue, setIssue] = useState<IssueRequest>({
    ticker: "",
    name: "",
    description: "",
    supply: "0.00",
    precision: 0,
    seal: "",
    iface: "",
    chain: "bitcoin",
    // chainFee: { none: "" },
  });
  const [confirmCreation, setConfirmCreation] = useState(false);
  const [asset, setAsset] = useState<backend.rgb.IssueResponse>({
    contractId: "",
    contract: { strict: "", armored: "" },
    iimplId: "",
    ticker: "",
    name: "",
    description: "",
    precision: 0,
    supply: BigInt(0),
    iface: ifaceFungibles,
    issueUtxo: "",
    genesis: { strict: "" },
    meta: {
      tokenIndex: 0,
      media: { attachments: [] },
    },
    issueMethod: "",
    created: BigInt(0),
    balance: { value: BigInt(0) },
    allocations: [],
  });
  const [conversion, setConversion] = useState(0);
  const navigate = useNavigate();
  const location = useLocation() as Location;
  const network = window.localStorage.getItem("network");
  const { walletData, vault, hash, lnCredentials, assetUtxos } = location.state;

  const username = localStorage.getItem("username");

  const amount =
    process.env.NODE_ENV === "development"
      ? Math.ceil((1.0 / conversion) * 100_000_000)
      : Math.ceil((100.0 / conversion) * 100_000_000);

  const handleSubmit = async () => {
    try {
      if (!assetUtxos.currentUtxoTokens) {
        setLoading(false);
        setError({
          title: "Error: No token UTXO",
          message: "No Asset UTXOs",
        });
        setOpen(true);
      }

      const avalaibleUtxo: NextUtxoResponse = await backend.rgb.nextUtxo(
        vault.private.nostrPrv,
        "rgb20"
      );

      const currentUtxoTokens = avalaibleUtxo.utxo;
      const req: IssueRequest = {
        ticker: issue.ticker,
        name: issue.name,
        description: issue.description,
        supply: issue.supply,
        precision: issue.precision,
        iface: ifaceFungibles,
        chain: issue.chain,
        seal: `${tapretPrefix}:${currentUtxoTokens}`,
      };

      console.log("seal", req.seal);
      console.log("issue req", req);

      const issueResponse = JSON.parse(
        await backend.issue_contract_proxy(vault.private.nostrPrv, req)
      ).data;

      const {
        contractId,
        contract: { armored },
      } = issueResponse;

      setAsset(issueResponse);
      if (publicAsset && network === "bitcoin" && (username?.length || 0) > 0) {
        const tokens = await backend.lightning.auth(
          lnCredentials.login,
          lnCredentials.password
        );
        if ("error" in tokens) {
          setLoading(false);
          setError({
            title: "Error Creating LN Invoice",
            message: `Authentication error: ${tokens.error}`,
          });
          setOpen(true);
        } else {
          const createFeeInvoice = await fetch(
            `${
              isExtension ? "https://beta.bitmask.app" : ""
            }/api/fee?purpose=registry`
          );
          const invoice = await createFeeInvoice.json();
          if (invoice.pr) {
            const payInvoice = await backend.lightning.payInvoice(
              invoice.pr,
              tokens.token
            );
            if (payInvoice.success) {
              await fetch(
                `${
                  isExtension ? "https://beta.bitmask.app" : ""
                }/api/createasset`,
                {
                  method: "POST",
                  headers: {
                    "Content-Type": "application/json",
                  },
                  body: JSON.stringify(
                    {
                      asset: {
                        creatorXpubkh: vault.public.xpubkh,
                        ticker: issue.ticker,
                        name: issue.name,
                        description: issue.description,
                        supply: issue.supply,
                        precision: issue.precision,
                        contractId,
                        contractGenesis: armored,
                        payment: { network: "LN", txid: invoice.payment_hash },
                      },
                      auth: {
                        xpubkh: vault.public.xpubkh,
                        xprvkh: vault.private.xprvkh,
                      },
                    },
                    (key, value) =>
                      typeof value === "bigint" ? value.toString() : value
                  ),
                }
              );

              navigate("/success", {
                state: {
                  walletData,
                  vault,
                  lnCredentials: null,
                  hash: invoice.payment_hash,
                  transaction: { assetType: "Lightning" },
                },
              });
            } else {
              // setLoading(false);
              // setError({
              //   title: "Error Paying LN Invoice",
              //   message: lndhubError(
              //     invoice?.error || "An unknown error occurred."
              //   ),
              // });
              // setOpen(true);
              console.info("LN payment failed, trying L1");

              const optimalFee = await getFeeRate();

              const tx = await backend.bitcoin.sendSats(
                vault.private.btcDescriptorXprv,
                vault.private.btcChangeDescriptorXprv,
                "bc1p962r7m5cnf755cxledguxmuevylhwawxcjqfq6vcm2h2ecu4c5dqa2az2m",
                BigInt(amount),
                true,
                optimalFee
              );

              await fetch(
                `${
                  isExtension ? "https://beta.bitmask.app" : ""
                }/api/createasset`,
                {
                  method: "POST",
                  headers: {
                    "Content-Type": "application/json",
                  },
                  body: JSON.stringify(
                    {
                      asset: {
                        creatorXpubkh: vault.public.xpubkh,
                        ticker: issue.ticker,
                        name: issue.name,
                        description: issue.description,
                        supply: issue.supply,
                        precision: issue.precision,
                        contractId,
                        contractGenesis: armored,
                        payment: { network: "L1", txid: tx.details.txid },
                      },
                      auth: {
                        xpubkh: vault.public.xpubkh,
                        xprvkh: vault.private.xprvkh,
                      },
                    },
                    (key, value) =>
                      typeof value === "bigint" ? value.toString() : value
                  ),
                }
              );
            }
          } else {
            setLoading(false);
            setError({
              title: "Error Creating LN Invoice",
              message: lndhubError(
                invoice?.error || "An unknown error occurred."
              ),
            });
            setOpen(true);
          }
        }
      } else {
        console.error("Unexpected error", { publicAsset, network, username });
      }
      setLoading(false);
    } catch (err: any) {
      console.error(err);
      setLoading(false);
      setError({ title: "Error creating asset", message: err.toString() });
      setOpen(true);
    }
  };

  const [lnWalletData, setLnWalletData] = useState<LnWalletData>();

  const lnWalletKey = `${network}.LnWalletData`;
  const retrieveLnWalletData = async (): Promise<LnWalletData> => {
    const result = (await lib.storageGet<LnWalletData>([lnWalletKey]))[
      lnWalletKey
    ];
    if (result) setLnWalletData(result);
    return result;
  };

  useEffect(() => {
    (async () => {
      const usd = await getConversion();
      setConversion(usd);
      await retrieveLnWalletData();
    })();
  }, []);

  if (asset.contract.armored !== "") {
    return (
      <div className="flex flex-col justify-center w-full h-full m-auto min-h-page 4xl:w-4/12 3xl:w-5/12 2xl:w-6/12 xl:w-7/12 lg:w-8/12">
        <div className="w-full h-auto py-6 m-auto text-center border-1 rounded-xl dark:border-darkmode-600">
          <div className="flex flex-col">
            <CheckCircle className="w-24 h-24 m-auto mb-6" />
            <p className="m-auto text-lg font-normal text-center text-black sm:text-xl dark:text-white">
              Asset created
            </p>
            <CopyButton
              handleOnClick={() => {
                navigator.clipboard.writeText(asset.contract.armored);
                setOpen(true);
              }}
              className="w-auto p-1 m-auto max-w-11/12"
              title="Copy contract"
            >
              {asset.contract.armored}
            </CopyButton>
          </div>
        </div>
        <div className="flex justify-center w-full py-6 mx-auto gap-6 sm:pb-12 lg:pb-20">
          <RoundedButton
            className="w-full text-base bg-yellow-500 border-2 border-yellow-500 text-newdarkmode-900"
            onClick={() => {
              navigate("/wallet", {
                state: {
                  wallet: walletData.name,
                  vault,
                  hash,
                  lnCredentials,
                },
              });
            }}
          >
            Okay
          </RoundedButton>
        </div>
        <CopiedToClipboard open={open} setOpen={setOpen} />
      </div>
    );
  }

  if (confirmCreation) {
    return (
      <PageWrapper
        title="Confirm Transaction"
        handlePageBack={() => setConfirmCreation(false)}
        handleCancel={() =>
          navigate("/wallet", {
            state: { wallet: walletData.name, vault, hash, lnCredentials },
          })
        }
        handleSubmit={async () => {
          setLoading(true);
          await handleSubmit();
        }}
        loading={loading}
        confirmation
      >
        <>
          <div className="flex flex-col text-center sm:bg-gray-300 space-y-6 sm:dark:bg-newdarkmode-800 sm:rounded-lg sm:shadow-lg sm:p-12">
            <h1 className="text-2xl font-semibold text-black dark:text-gray-300">
              Register RGB asset
            </h1>
            <p className="w-[85%] my-auto text-base lg:text-lg dark:text-white font-light sm:w-full">
              Add {asset.ticker} to the BitMask Asset Registry? There will be a
              $100 listing fee, paid in BTC, from either your LN or L1 wallet
              balance. This comes out to {amount} in sats.
            </p>
            <div className="md:grid md:grid-cols-2 gap-3">
              <p className="text-base font-normal text-gray-800 dark:text-gray-200">
                LN Balance:
              </p>
              <p className="text-base font-normal text-gray-800 dark:text-gray-200">
                BTC L1 Balance:
              </p>
              <p className="text-base font-normal text-gray-800 dark:text-gray-200">
                $
                {LNDHUBX && !DISABLE_LN && lnWalletData
                  ? (Number(lnWalletData.balance.balance) * conversion).toFixed(
                      2
                    )
                  : "Unavailable"}{" "}
                in sats
              </p>
              <p className="text-base font-normal text-gray-800 dark:text-gray-200">
                $
                {(
                  (walletData.balance.confirmed / 100_000_000) *
                  conversion
                ).toFixed(2)}{" "}
                in sats
              </p>
            </div>
          </div>
          <ErrorModal
            open={open}
            setOpen={setOpen}
            message={error.message}
            title={error.title}
          />
        </>
      </PageWrapper>
    );
  }

  return (
    <PageWrapper
      title="Create Asset"
      handlePageBack={() =>
        navigate("/wallet", {
          state: { wallet: walletData.name, vault, hash, lnCredentials },
        })
      }
      handleCancel={() =>
        navigate("/wallet", {
          state: { wallet: walletData.name, vault, hash, lnCredentials },
        })
      }
      handleSubmit={async () => {
        if (issue.ticker.length <= 2) {
          setError({
            title: "Value Error",
            message: `Ticker must be at least 3 characters. Ticker length: ${issue.ticker.length}.`,
          });
          setOpen(true);
        } else if (issue.ticker.length > 40) {
          setError({
            title: "Value Error",
            message: `Ticker can be a maximum of 40 characters. Ticker length: ${issue.ticker.length}.`,
          });
          setOpen(true);
        } else if (issue.precision > 18) {
          setError({
            title: "Value Error",
            message: `Precision can be a maximum of 18, Provided value: ${issue.precision}.`,
          });
          setOpen(true);
        } else {
          setLoading(true);
          if (publicAsset) {
            setLoading(false);
            setConfirmCreation(true);
          } else {
            await handleSubmit();
          }
        }
      }}
      loading={loading}
    >
      <div className="flex flex-col justify-center w-full h-auto">
        <FullWidthTextField
          label="Ticker"
          type="text"
          className="w-full p-2 mx-0 xs:p-3 min-w-11/12 xs:min-w-9/12 flex-grow-default"
          placeholder="Ticker"
          onChange={(e) =>
            setIssue({ ...issue, ticker: e.target.value.toUpperCase() })
          }
        />
        <FullWidthTextField
          label="Asset Name"
          type="text"
          className="w-full p-2 mx-0 xs:p-3 min-w-11/12 xs:min-w-9/12 flex-grow-default"
          placeholder="Name"
          onChange={(e) => setIssue({ ...issue, name: e.target.value })}
        />
        <FullWidthTextField
          label="Total Supply"
          type="number"
          className="w-full p-2 mx-0 xs:p-3 min-w-11/12 xs:min-w-9/12 flex-grow-default"
          placeholder="Supply"
          min="1"
          onChange={(e) => setIssue({ ...issue, supply: e.target.value })}
        />
        <FullWidthTextField
          label="Precision"
          type="number"
          className="w-full p-2 mx-0 mb-1.5 xs:p-3 min-w-11/12 xs:min-w-9/12 flex-grow-default"
          placeholder="Precision"
          min="0"
          max="18"
          onChange={(e) =>
            setIssue({ ...issue, precision: parseInt(e.target.value, 10) })
          }
        />
        {network === "bitcoin" && (username?.length || 0) > 0 && (
          <LeftLabelToggle
            label="Set asset visibility"
            description="Do you want this asset to be publicly listed on the BitMask Asset Registry? This will cost $100."
            enabled={publicAsset}
            setEnabled={setPublicAsset}
          />
        )}
      </div>
      <ErrorModal
        open={open}
        setOpen={setOpen}
        message={error.message}
        title={error.title}
      />
    </PageWrapper>
  );
};

export default CreateAsset;
