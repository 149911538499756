/* eslint-disable no-nested-ternary */
/* eslint-disable  @typescript-eslint/no-explicit-any */

import React, { useCallback, useRef } from "react";
import { DISABLE_LN, LNDHUBX } from "bitmask-segwit/constants";
import "swiper/css";
import "swiper/css/pagination";
import { Navigation, A11y, Controller } from "swiper";
import { Swiper, SwiperSlide } from "swiper/react";
import Skeleton from "react-loading-skeleton";
import "react-loading-skeleton/dist/skeleton.css";

import { addBalance, classNames, getLargeNumber } from "src/Hooks/util";
import { ChevronLeftIcon, ChevronRightIcon } from "@heroicons/react/24/solid";
import Bitcoin from "../Icons/Bitcoin";
import LightningNetwork from "../Icons/LightningNetwork";

const BitcoinBalanceData = ({ isActive, handleNext, ...props }) => (
  <div
    className={classNames(
      isActive ? "ml-6 w-auto" : "w-full",
      "flex-col m-auto h-full p-6 border-1 dark:border-darkmode-700 md:border-none rounded-lg md:rounded-none relative cursor-grab"
    )}
  >
    <div className="flex flex-col m-auto">
      <div className="flex flex-row m-auto flex-nowrap">
        <Bitcoin className="w-6 h-6 my-auto mr-2" />
        <p className="my-auto text-sm font-thin text-center text-gray-500 lg:text-base">
          Bitcoin (L1)
        </p>
      </div>
      <h1 className="pt-4 m-auto text-lg font-normal text-center text-black lg:text-xl md:font-semibold dark:text-white flex-grow-default">
        {props.loadingBitcoin && !props.walletData.balance ? (
          <Skeleton
            baseColor="#1C1C1C"
            highlightColor="#343434"
            className="max-w-40 min-w-40"
          />
        ) : (
          <>
            {addBalance(props.walletData?.balance) <= 999_999
              ? addBalance(props.walletData?.balance).toLocaleString()
              : getLargeNumber(addBalance(props.walletData?.balance))}{" "}
            {props.network !== "bitcoin" ? "tSats" : "Sats"}
          </>
        )}
      </h1>
    </div>
    <div className="flex flex-row mt-3">
      <p className="text-sm font-thin text-center text-black md:my-0 md:flex-grow-default dark:text-gray-500">
        {props.loadingBitcoin && !props.walletData.balance ? (
          <Skeleton
            baseColor="#1C1C1C"
            highlightColor="#343434"
            className="w-auto min-w-30 max-w-40"
          />
        ) : (
          <>
            {Number(
              addBalance(props.walletData?.balance) / 100_000_000
            ).toLocaleString(undefined, {
              minimumFractionDigits: 8,
            })}{" "}
            {props.network !== "bitcoin" ? "tBTC" : "BTC"}
          </>
        )}
      </p>
      <p className="ml-auto text-sm font-thin text-center text-black md:my-0 md:mx-0 md:flex-grow-default dark:text-gray-500">
        {props.loadingBitcoin && !props.walletData.balance ? (
          <Skeleton
            baseColor="#1C1C1C"
            highlightColor="#343434"
            className="w-auto min-w-30 max-w-40"
          />
        ) : (
          <>
            {Number(
              (addBalance(props.walletData?.balance) / 100_000_000) *
                props.conversion
            ).toLocaleString(undefined, {
              minimumFractionDigits: 3,
            })}{" "}
            USD
          </>
        )}
      </p>
    </div>
    {isActive && (
      <button
        type="button"
        aria-label="swipe right"
        className="absolute top-0 bottom-0 my-auto ml-auto cursor-pointer -right-3"
        onClick={() => handleNext()}
      >
        <div className="p-2 m-auto rounded-full bg-darkmode-700">
          <ChevronRightIcon className="w-5 h-5 m-auto font-normal text-white" />
        </div>
      </button>
    )}
  </div>
);

const LnBalanceData = ({ isActive, handlePrev, ...props }) => (
  <div
    className={classNames(
      isActive ? "mr-6 w-auto" : "w-full",
      "flex-col m-auto h-full p-6 border-1 dark:border-darkmode-700 md:border-none md:rounded-none rounded-lg relative cursor-grab"
    )}
  >
    {isActive && (
      <button
        type="button"
        aria-label="swipe left"
        className="absolute top-0 bottom-0 my-auto mr-auto cursor-pointer -left-3"
        onClick={() => handlePrev()}
      >
        <div className="p-2 m-auto rounded-full bg-darkmode-700">
          <ChevronLeftIcon className="w-5 h-5 m-auto text-white" />
        </div>
      </button>
    )}
    <div className="flex flex-col m-auto">
      <div className="flex flex-row m-auto flex-nowrap">
        <LightningNetwork className="w-6 h-6 my-auto mr-2" />
        <p className="m-auto text-sm font-thin text-center text-gray-500 lg:text-base">
          Lightning (L2)
        </p>
      </div>
      <h1 className="pt-4 m-auto text-lg font-normal text-center text-black lg:text-xl md:font-semibold dark:text-white flex-grow-default">
        {props.loadingLightning && !props.lnWalletData?.balance.balance ? (
          <Skeleton
            baseColor="#1C1C1C"
            highlightColor="#343434"
            className="max-w-40 min-w-40"
          />
        ) : (
          <>
            {LNDHUBX && !DISABLE_LN && props.lnWalletData
              ? Number(props.lnWalletData.balance.balance) * 100_000_000 <=
                999_999
                ? (
                    Number(props.lnWalletData.balance.balance) * 100_000_000
                  ).toLocaleString()
                : getLargeNumber(
                    Number(props.lnWalletData.balance.balance) * 100_000_000
                  )
              : "Unavailable"}{" "}
            {LNDHUBX &&
              !DISABLE_LN &&
              props.lnWalletData &&
              (props.network !== "bitcoin" ? "tSats" : "Sats")}
          </>
        )}
      </h1>
    </div>
    {LNDHUBX && !DISABLE_LN ? (
      <div className="flex flex-row mt-3">
        <p className="text-sm font-thin text-center text-black md:my-0 md:flex-grow-default dark:text-gray-500">
          {props.loadingLightning && !props.lnWalletData?.balance.balance ? (
            <Skeleton
              baseColor="#1C1C1C"
              highlightColor="#343434"
              className="w-auto min-w-30 max-w-40"
            />
          ) : (
            <>
              {Number(props.lnWalletData?.balance.balance).toLocaleString(
                undefined,
                {
                  minimumFractionDigits: 8,
                }
              )}{" "}
              {props.network !== "bitcoin" ? "tBTC" : "BTC"}
            </>
          )}
        </p>
        <p className="ml-auto text-sm font-thin text-center text-black md:my-0 md:mx-0 md:flex-grow-default dark:text-gray-500">
          {props.loadingLightning && !props.lnWalletData?.balance.balance ? (
            <Skeleton
              baseColor="#1C1C1C"
              highlightColor="#343434"
              className="w-auto min-w-30 max-w-40"
            />
          ) : (
            <>
              {(
                Number(props.lnWalletData?.balance.balance) * props.conversion
              ).toLocaleString(undefined, {
                minimumFractionDigits: 3,
              })}{" "}
              USD
            </>
          )}
        </p>
      </div>
    ) : (
      <p className="mb-2 text-sm font-light text-left text-black md:my-0 md:flex-grow-default dark:text-gray-300">
        {!props.loading && "Unavailable"}
      </p>
    )}
  </div>
);

const Balances = ({ ...props }) => {
  const sliderRef = useRef<any>(null);
  const handlePrev = useCallback(() => {
    if (!sliderRef.current) return;
    sliderRef.current.swiper.slidePrev();
  }, []);

  const handleNext = useCallback(() => {
    if (!sliderRef.current) return;
    sliderRef.current.swiper.slideNext();
  }, []);

  return (
    <>
      <div className="flex w-full h-full my-3 rounded-lg xs:my-6 xs:w-full lg:border-1 lg:border-darkmode-600 md:hidden">
        <Swiper
          ref={sliderRef}
          modules={[Navigation, A11y, Controller]}
          spaceBetween={24}
          slidesPerView={1.1}
          className="w-auto h-full"
        >
          <SwiperSlide>
            {({ isActive }) => (
              <BitcoinBalanceData
                isActive={isActive}
                handleNext={handleNext}
                {...props}
              />
            )}
          </SwiperSlide>
          <SwiperSlide>
            {({ isActive }) => (
              <LnBalanceData
                isActive={isActive}
                handlePrev={handlePrev}
                {...props}
              />
            )}
          </SwiperSlide>
        </Swiper>
      </div>
      <div className="hidden w-full h-full px-6 my-3 rounded-lg xs:my-6 xs:w-full md:border-1 md:border-darkmode-600 md:flex md:divide-x-1 md:divide-solid md:dark:divide-darkmode-700">
        <BitcoinBalanceData
          isActive={false}
          handleNext={handleNext}
          {...props}
        />
        <LnBalanceData isActive={false} handlePrev={handlePrev} {...props} />
      </div>
    </>
  );
};

export default Balances;
