/* eslint-disable no-unused-vars */
/* eslint-disable arrow-body-style */
/* eslint-disable no-shadow */
/* eslint-disable jsx-a11y/control-has-associated-label */
/* global chrome */
import React, { useContext } from "react";
import { useNavigate } from "react-router-dom";
import { Disclosure } from "@headlessui/react";
import { ChevronDownIcon } from "@heroicons/react/24/solid";
import * as backend from "bitmask-segwit";

import { MainNavContext } from "src/Hooks/MainNavContext";
import { isExtension } from "src/lib";
import Dropdown from "src/Components/Inputs/NetworkDropdown";
import NavbarMenu from "src/Components/Navbar/Menu";
import Refresh from "src/Components/Icons/Refresh";
import LogoDark from "src/Components/Icons/LogoDark";
import ChevronDown from "../Icons/ChevronDown";

export interface NavItem {
  [key: string]: string;
}

const items: NavItem[] = [
  { "Bitcoin Mainnet": backend.constants.Network.bitcoin },
  { "Bitcoin Testnet": backend.constants.Network.testnet },
  { "Bitcoin Signet ": backend.constants.Network.signet },
  { "Bitcoin Regtest": backend.constants.Network.regtest },
];

const MainNav = () => {
  const { setMainNav } = useContext(MainNavContext);
  const navigate = useNavigate();

  return (
    <Disclosure
      as="header"
      className="relative z-10 bg-gray-100 dark:bg-darkmode-900"
    >
      {({ open }) => (
        <div className="min-w-full px-2 max-w-7xl sm:px-4 lg:divide-y lg:divide-gray-200 lg:px-8">
          <div className="relative flex justify-between h-12">
            <div className="relative z-0 flex-grow-default xl:w-1/5 lg:pr-8 md:pr-0 lg:px-0">
              <div className="flex m-auto flex-nowrap">
                <button
                  type="button"
                  onClick={() => navigate("/home")}
                  className="mx-2 my-auto cursor-pointer sm:mx-4"
                >
                  <div className="flex m-auto flex-nowrap">
                    <LogoDark className="w-32 h-12 text-black dark:text-white" />
                  </div>
                </button>
              </div>
            </div>
            <div className="px-2 m-auto">
              <div className="flex justify-center w-full m-auto">
                <div className="w-auto m-auto">
                  <Dropdown
                    items={items}
                    className="flex justify-center w-auto h-8 px-3 my-auto mr-2 text-sm font-normal text-gray-800 bg-gray-100 rounded-lg dark:bg-newdarkmode-800 max-w-32 sm:max-w-full disabled:opacity-50 disabled:cursor-not-allowed shadow-sm dark:text-gray-300 hover:bg-gray-200 disabled:pointer-events-none dark:hover:bg-newdarkmode-700 focus:outline-none focus:ring-1 dark:focus:ring-newdarkmode-600"
                    title="Select Network"
                  >
                    <span className="m-auto mx-1 text-base truncate flex-shrink-default">
                      {window.localStorage.getItem("network")}
                    </span>
                    <ChevronDown
                      className="w-3 h-3 m-auto ml-0.5 flex-grow-default"
                      aria-hidden="true"
                    />
                  </Dropdown>
                </div>
                <button
                  type="button"
                  className="mx-3 my-auto"
                  onClick={() => {
                    window.location.reload();
                    if (setMainNav) setMainNav(true);
                  }}
                >
                  <Refresh className="w-5 h-5 m-auto font-light text-gray-800 dark:text-white" />
                </button>
                <div className="flex justify-center h-full m-auto ml-1">
                  <NavbarMenu className="m-auto bg-darkmode-800 rounded-xl px-3 py-1.5 dark:hover:bg-newdarkmode-700" />
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
    </Disclosure>
  );
};

export default MainNav;
