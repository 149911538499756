import React, { useState, useEffect } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import * as backend from "bitmask-segwit";

import PageWrapper from "src/Components/Layout/Wrappers/PageWrapper";
import ErrorModal from "src/Components/Modals/Error";
import { getConversion, lndhubError } from "src/Hooks/util";
import { Location } from "src/types";
import { isExtension } from "src/lib";

const LightningConfirm = () => {
  const navigate = useNavigate();
  const location = useLocation() as Location;
  const {
    wallet,
    vault,
    lnCredentials: oldLnCredentials,
    lnInvoice,
    hash,
    usernameInvoice,
  } = location.state;
  const [open, setOpen] = useState(false);
  const [error, setError] = useState({
    title: "",
    message: "",
  });
  const [loading, setLoading] = useState(false);
  const [conversion, setConversion] = useState(0);
  const [lnCredentials, setLnCredentials] = useState(oldLnCredentials);
  const network = window.localStorage.getItem("network");

  const amount = lnInvoice
    ? // eslint-disable-next-line global-require, @typescript-eslint/no-var-requires
      require("light-bolt11-decoder").decode(lnInvoice).sections[2].value *
      0.001
    : usernameInvoice.amount;

  const handlePayLightningInvoice = async () => {
    setLoading(true);
    const tokens = await backend.lightning.auth(
      vault.public.xpubkh,
      vault.private.xprvkh
    );
    if ("error" in tokens) {
      setLoading(false);
      console.error("LNDHubX authentication error:", tokens.error);
      setError({
        title: "Error Creating LN Invoice",
        message: `Authentication error: ${tokens.error}`,
      });
      setOpen(true);
    } else {
      const newLnCredentials = {
        login: vault.public.xpubkh,
        password: vault.private.xprvkh,
        refreshToken: tokens.refresh,
        accessToken: tokens.token,
      };
      setLnCredentials(newLnCredentials);
      if (lnInvoice) {
        try {
          const invoice = await backend.lightning.payInvoice(
            lnInvoice,
            newLnCredentials.accessToken
          );
          if (invoice.error) {
            setLoading(false);
            setError({
              title: "Error Creating LN Invoice",
              message: lndhubError(invoice.error),
            });
            setOpen(true);
          } else if (invoice) {
            navigate("/success", {
              state: {
                wallet,
                vault,
                lnCredentials: newLnCredentials,
                lnInvoice,
                hash,
                transaction: { assetType: "Lightning" },
              },
            });
          }
        } catch (err) {
          setLoading(false);
          setError({
            title: "Error Paying LN Invoice",
            message:
              (err as Error)?.message ||
              err?.toString() ||
              "Unhandled exception",
          });
          setOpen(true);
        }
      } else {
        try {
          const username = usernameInvoice.username.replace("@bitmask.app", "");
          const createUsernameInvoice = await fetch(
            `${
              isExtension ? "https://beta.bitmask.app" : ""
            }/api/pay/${username.toLowerCase()}?amount=${
              usernameInvoice.amount * 1000
            }`
          );
          const invoice = await createUsernameInvoice.json();
          if (invoice.pr) {
            const payInvoice = await backend.lightning.payInvoice(
              invoice.pr,
              newLnCredentials.accessToken
            );
            if (payInvoice.success) {
              navigate("/success", {
                state: {
                  wallet,
                  vault,
                  lnCredentials: null,
                  hash: invoice.payment_hash,
                  transaction: { assetType: "Lightning" },
                },
              });
            } else {
              setLoading(false);
              setError({
                title: "Error Paying LN Invoice",
                message: "An unknown error occurred.",
              });
              setOpen(true);
            }
          } else {
            setLoading(false);
            setError({
              title: "Error Creating LN Invoice",
              message: "An unknown error occurred.",
            });
            setOpen(true);
          }
        } catch (err) {
          setLoading(false);
          setError({
            title: "Error Sending to Lightning Username",
            message:
              (err as Error)?.message ||
              err?.toString() ||
              "Unhandled exception",
          });
          setOpen(true);
        }
      }
    }
  };

  useEffect(() => {
    (async () => {
      const rate = await getConversion();
      setConversion(rate);
    })();
  }, []);

  return (
    <PageWrapper
      title="Confirm Lightning Transaction"
      handlePageBack={() =>
        navigate("/wallet", {
          state: { wallet, vault, lnCredentials, hash },
        })
      }
      handleCancel={() =>
        navigate("/wallet", {
          state: { wallet, vault, lnCredentials, hash },
        })
      }
      handleSubmit={() => handlePayLightningInvoice()}
      loading={loading}
      confirmation
    >
      <div className="w-full p-6 my-auto mb-6 text-center sm:mb-12 border-1 dark:border-darkmode-700 rounded-xl">
        <p className="my-auto text-base sm:text-lg font-thin text-gray-800 truncate dark:text-gray-500 mb-1.5">
          Sending
        </p>
        <p className="mb-3 text-2xl font-semibold text-black dark:text-gray-300">
          {amount.toLocaleString()} {network !== "bitcoin" ? "tSats" : "Sats"}
        </p>
        <div className="flex flex-row justify-center flex-nowrap gap-3">
          <p className="text-base font-thin text-gray-800 dark:text-gray-500">
            {Number(amount / 100_000_000).toLocaleString(undefined, {
              minimumFractionDigits: 8,
            })}{" "}
            BTC
          </p>
          <p className="text-base font-thin text-gray-800 dark:text-gray-500">
            {Number((Number(amount) / 100_000_000) * conversion).toLocaleString(
              undefined,
              { minimumFractionDigits: 3 }
            )}{" "}
            USD
          </p>
        </div>
      </div>
      <div className="flex-col px-3 flex-grow-default">
        <div className="flex flex-nowrap">
          <p className="mb-1 text-base font-semibold text-gray-700 sm:text-lg dark:text-gray-400">
            Total
          </p>
          <p className="my-auto ml-auto text-lg font-medium text-right text-black truncate dark:text-white">
            {amount.toLocaleString()} {network !== "bitcoin" ? "tSats" : "Sats"}{" "}
          </p>
        </div>
        <div className="flex flex-nowrap">
          <p className="mt-1 text-base font-thin text-gray-800 dark:text-gray-500">
            Amount + Fee
          </p>
          <p className="my-auto ml-auto text-base font-thin text-right text-gray-800 truncate dark:text-gray-500">
            $
            {((Number(amount) / 100_000_000) * conversion).toLocaleString(
              undefined,
              {
                minimumFractionDigits: 3,
              }
            )}{" "}
            USD
          </p>
        </div>
      </div>

      <ErrorModal
        open={open}
        setOpen={setOpen}
        message={error.message}
        title={error.title}
      />
    </PageWrapper>
  );
};

export default LightningConfirm;
