/* eslint-disable no-unused-vars */
import React, { useState, useLayoutEffect } from "react";
import {
  PendingStep,
  Rgb2Contracts,
  RgbAirdrop,
  RgbAuction,
  RgbConsecutive,
  RgbFundVaultUDA,
  RgbFundVaultUDASwap,
  RgbFundVault,
  RgbGeneral,
  RgbHotswap,
  RgbIFaces,
  RgbListContracts,
  RgbP2P,
  RgbUDA,
} from "src/Components/Debug";
import SecondaryNav from "src/Components/Navbar/SecondaryNav";
import useWindowSize from "src/Hooks/useWindowSize";

const Debug = () => {
  const size = useWindowSize();
  const [height, setHeight] = useState(0);

  const options = [
    {
      name: "RGB (Fungible Flow)",
    },
    {
      name: "RGB (UDA Flow)",
    },
    {
      name: "RGB (Consecutive Transfer Flow)",
    },
    {
      name: "RGB (Two Contracts Flow)",
    },
    {
      name: "RGB (Two Ifaces Flow)",
    },
    {
      name: "RGB (Fund Vault Flow)",
    },
    {
      name: "RGB (List Contracts Flow)",
    },
    {
      name: "RGB (P2P Flow)",
    },
    {
      name: "RGB (HotSwap Flow)",
    },
    {
      name: "RGB (Auction Flow)",
    },
    {
      name: "RGB (Airdrop Flow)",
    },
    {
      name: "RGB (Fund Vault UDA Flow)",
    },
    {
      name: "RGB (Fund Vault UDA Flow with swap)",
    },
  ];

  const pendingOptions = [
    "RGB (Transfer Flow with Fee Rate)",
    "RGB (Consecutive Swaps Flow)",
    "RGB (Batch Transaction Flow)",
    "RGB (Batch Swaps Flow)",
    "BTC (Batch Flow)",
  ];

  useLayoutEffect(() => {
    setHeight(size.height - 56);
  }, [size]);

  return (
    <div className="h-full min-h-full overflow-hidden bggradientlanding">
      <SecondaryNav
        className="text-white bggradientlanding"
        menuItems={[
          { name: "Wallet", route: "/wallet" },
          { name: "Home", route: "/" },
          { name: "Registry", route: "/registry" },
          {
            name: "GitHub",
            link: "https://github.com/diba-io/bitmask-segwit/",
          },
        ]}
      />
      <div
        style={{
          height,
          minHeight: height,
        }}
        className="w-full min-h-screen m-auto overflow-y-auto darkscrollbar"
      >
        <div className="flex flex-col w-11/12 m-auto">
          <h1 className="m-auto mt-3 mb-6 text-2xl font-bold text-white sm:text-4xl font-integral">
            Troubleshooting
          </h1>
          <div className="flex flex-col w-full mx-auto lg:flex-row gap-6 md:gap-9 xl:gap-20">
            <div className="flex-grow-default">
              <RgbGeneral value={options[0].name} />
              <RgbUDA value={options[1].name} />
              <RgbConsecutive value={options[2].name} />
              <Rgb2Contracts value={options[3].name} />
              <RgbIFaces value={options[4].name} />
              <RgbFundVault value={options[5].name} />
              <RgbListContracts value={options[6].name} />
              <RgbP2P value={options[7].name} />
              <RgbHotswap value={options[8].name} />
              <RgbAuction value={options[9].name} />
              <RgbAirdrop value={options[10].name} />
              <RgbFundVaultUDA value={options[11].name} />
              <RgbFundVaultUDASwap value={options[12].name} />
            </div>
          </div>

          <div className="flex flex-col w-full mx-auto mt-9 lg:flex-row gap-6 md:gap-9 xl:gap-20">
            <h5 className="m-auto font-bold text-white font-integral">
              Pending Steps
            </h5>
            <div className="flex-grow-default">
              {pendingOptions.map((opt: string) => (
                <PendingStep value={opt} />
              ))}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Debug;
