/* eslint-disable no-unused-vars */
/* eslint-disable arrow-body-style */
/* eslint-disable jsx-a11y/anchor-is-valid */
/* global chrome */
import React, { Fragment } from "react";
import { Menu, Transition } from "@headlessui/react";
import { useNavigate, useLocation } from "react-router-dom";
import * as backend from "bitmask-segwit";
import { LNDHUBX, init } from "bitmask-segwit/constants";

import { classNames, getHash } from "src/Hooks/util";
import { isExtension } from "src/lib";
import { importWallet } from "src/Pages/WalletImport";
import { NavItem } from "../Navbar/MainNav";

const Dropdown = ({ className, ...props }) => {
  const navigate = useNavigate();
  const reloadScriptAndTabs = () => {
    if (isExtension) {
      chrome.runtime.sendMessage({
        call: "reload_script_and_tabs",
      });
    }
  };

  return (
    <Menu as="div" className="relative m-auto text-left">
      <div>
        <Menu.Button className={className}>{props.children}</Menu.Button>
      </div>
      <Transition
        as={Fragment}
        enter="transition ease-out duration-100"
        enterFrom="transform opacity-0 scale-95"
        enterTo="transform opacity-100 scale-100"
        leave="transition ease-in duration-75"
        leaveFrom="transform opacity-100 scale-100"
        leaveTo="transform opacity-0 scale-95"
      >
        <Menu.Items
          static
          className="absolute right-0 w-56 mt-2 bg-gray-100 shadow-lg origin-top-right rounded-md dark:bg-newdarkmode-800 ring-1 ring-black ring-opacity-5 divide-y divide-gray-100 focus:outline-none"
        >
          {props.items.map((item: NavItem) => (
            <div key={JSON.stringify(item)} className="">
              <Menu.Item>
                {({ active }) => (
                  <button
                    type="button"
                    className={classNames(
                      active
                        ? "text-gray-900 dark:text-gray-600"
                        : "text-gray-900 dark:text-gray-500",
                      "block px-4 py-3 text-base w-full h-auto text-left dark:hover:text-gray-600"
                    )}
                    onClick={async () => {
                      const network: string =
                        Object.values(item)[0] || "bitcoin";
                      console.debug({ network });
                      window.localStorage.setItem("network", network);

                      const handleNetworkSwitch = async () => {
                        try {
                          await init(network);
                          const encryptedDescriptors =
                            window.localStorage.getItem(`${network}Descriptor`);
                          const hash = await getHash();
                          if (hash) {
                            window.localStorage.setItem(
                              "loadNetworkChange",
                              "true"
                            );
                            const vault = await backend.bitcoin.decryptWallet(
                              hash,
                              encryptedDescriptors as string
                            );

                            let refresh = "";
                            let token = "";

                            const navigateToWallet = () => {
                              if (isExtension) {
                                chrome.runtime.sendMessage({
                                  call: "reload_script_and_tabs",
                                });
                              }

                              navigate("/wallet", {
                                state: {
                                  wallet: "wallet_01",
                                  vault,
                                  hash,
                                  network,
                                  lnCredentials: {
                                    login: vault.public.xpubkh,
                                    password: vault.private.xprvkh,
                                    refreshToken: refresh,
                                    accessToken: token,
                                  },
                                },
                                replace: true,
                              });
                            };

                            if (LNDHUBX) {
                              const tokens = await backend.lightning.auth(
                                vault.public.xpubkh,
                                vault.private.xprvkh
                              );

                              if ("error" in tokens) {
                                if (tokens.error === "UserDoesNotExist") {
                                  await backend.lightning.createWallet(
                                    vault.public.xpubkh,
                                    vault.private.xprvkh
                                  );
                                  const retryTokens =
                                    await backend.lightning.auth(
                                      vault.public.xpubkh,
                                      vault.private.xprvkh
                                    );
                                  if ("error" in retryTokens) {
                                    console.error(tokens.error);
                                  } else {
                                    refresh = retryTokens.refresh;
                                    token = retryTokens.token;
                                    navigateToWallet();
                                  }
                                } else {
                                  console.error(tokens.error, "tokens error");
                                }
                              } else {
                                navigateToWallet();
                              }
                            } else {
                              navigateToWallet();
                            }
                          } else {
                            localStorage.setItem("lockWallet", "true");
                            navigate("/signin", { replace: true });
                          }
                        } catch (error) {
                          console.log("error in network switch", error);
                          if (
                            error
                              ?.toString()
                              .toLowerCase()
                              .includes("invalid network") ||
                            error
                              ?.toString()
                              .toLowerCase()
                              .includes("missing field `uid`")
                          ) {
                            console.info(
                              "compensating by reimporting the wallet"
                            );

                            const hash = await getHash();

                            if (hash) {
                              const encryptedDescriptors =
                                window.localStorage.getItem(
                                  "bitcoinDescriptor"
                                );
                              const vault = await backend.bitcoin.decryptWallet(
                                hash,
                                encryptedDescriptors as string
                              );

                              await importWallet({
                                hash,
                                words: vault.mnemonic,
                                navigate,
                              });
                            } else {
                              console.error("failed to compensate");
                            }
                          } else {
                            console.warn("unable to compensate for error");
                          }
                        }
                      };

                      await handleNetworkSwitch();
                    }}
                  >
                    <div className="m-auto font-thin text-gray-500">
                      {Object.keys(item) as unknown as string}
                    </div>
                  </button>
                )}
              </Menu.Item>
            </div>
          ))}
        </Menu.Items>
      </Transition>
    </Menu>
  );
};

export default Dropdown;
