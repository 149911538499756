/* eslint-disable import/prefer-default-export */
/* eslint-disable no-nested-ternary */
import React from "react";

const { CONTEXT = "", VERSION = "" } = process.env;

const RELEASE_NOTES = [
  {
    version: "0.7.1",
    content: [
      <p>RGB 0.11 beta 5 support</p>,
      <p>Transfers enabled on testnet</p>,
      <p>Lots of bugfixes</p>,
      <p>Registry Search</p>,
    ],
  },
  {
    version: "0.7.0",
    content: [
      // <p>
      //   Password sync in extension - The wallet password should now sync between
      //   the popup and the extension and should generally ask for your password
      //   less often.
      // </p>,
      // <p>
      //   Transfer batching - When multiple consecutive RGB transfers are made
      //   from the same UTXO in the same block, the old transfer transaction is
      //   Replaced By a slightly higher Fee (RBF), and the second transfer gets
      //   added to the wallet. This can be done multiple times until the block is
      //   mined. For best results, start with a lower fee rate.
      // </p>,
      // <p>Unwanted contracts can now be hidden</p>,
      <p>RGB 0.11 beta</p>,
      <p>Since RGB is in beta, asset transfer is disabled</p>,
      <p>The mainnet BitMask Asset Registry is ready</p>,
      <p>
        Vaults will need to be funded again due to a protocol change in the RGB
        wallet derivation path
      </p>,
      <p>
        Contracts can now be published in case their genesis is blank in the
        registry. Tokens with the same ticker by the same user will be
        overwritten by the latest contract genesis.
      </p>,
      <p>More asset details are visible</p>,
      <p>Fund vault transaction id is now provided</p>,
      <p>Usernames should no longer be reset accidentally</p>,
    ],
  },
  {
    version: "0.6.3",
    content: [
      <p>Now you can do transfers of tokens and UDAs</p>,
      <p>
        You can also set whether tokens you issue are published to a public list
        of assets that will be available at a future date
      </p>,
    ],
  },
  {
    version: "0.6.2",
    content: [
      <p>
        In the Account Details screen, you can now update your bitmask.app
        username.
      </p>,
      <p>
        It is also possible to use that same username with Nostr as a NIP-05
        identifier.
      </p>,
      <p>
        Your bitmask.app username can also be used to make payments as a
        Lightning address.
      </p>,
      <p>
        We now have a feature to &quot;Send Max&quot; so you can transfer all
        funds from your BitMask wallet without leaving dust. This is helpful in
        scenarios where you want to upgrade from a 12 word seed to a new 24 word
        seed by creating a new wallet, which we would recommend doing now with
        this release if you have not yet already done so.
      </p>,
    ],
  },
  {
    version: "0.6.1",
    content: [
      <p>
        There were several one-time consensus-breaking changes made to RGB, so
        unfortunately, those assets are no longer available. In the future,
        bridging solutions will be made available to our users to update old
        assets.
      </p>,
      <p>
        We made several important security fixes, including default 24 word
        seeds. The old 12 word seeds were not generated with enough entropy to
        be secure.
      </p>,
      <p>
        We highly recommend upgrading to a new wallet with the 24 word seed.
      </p>,
      <p>Syncing performance should be improved.</p>,
      <p>Many errors should be resolved.</p>,
      <p>RGB vault funding and asset minting should work much better now.</p>,
      <p>
        Please be careful with your funds, and report any issues in our Telegram
        group.
      </p>,
      <p>
        One more thing, there&apos;s a new account details screen where you can
        see your wallet-generated Nostr npub and nsec, and also your xpub for
        watch-only wallets.
      </p>,
    ],
  },
  {
    version: "0.6.0",
    content: [
      <p>RGB 0.10 assets now supported, including RGB20 and RGB21</p>,
      <p>Wallet data is stored in end-to-end encrypted Carbonado format</p>,
      <p>Nostr npub and nprv keys are generated for the wallet</p>,
      <p>Network selector now works (wen mainnet? now mainnet.)</p>,
      <p>Settings screen to recover encrypted mnemonic</p>,
      <p>Wallet now encrypted with high-security Argon2id passwords</p>,
      <p>
        Due to the password change, all old testnet wallets will be cleared
      </p>,
      <p>They can be restored using your old mnemonic seed</p>,
      <p>Please be careful, and report any issues in our Telegram group.</p>,
    ],
  },
  {
    version: "0.5.2",
    content: [
      <p>Audio UDAs minting compatibility with the DIBA marketplace</p>,
      <p>Playback of Audio UDAs within the wallet</p>,
      <p>
        Lightning support now added to the web wallet, in addition to the
        extension
      </p>,
      <p>Wallet UI fixes and improvements</p>,
    ],
  },
  {
    version: "0.4.3",
    content: [
      <p>Lightning wallet support added</p>,
      <p>Added QR scanning</p>,
      <p>Assets and UDA import and transfer fixes</p>,
      <p>Payjoin now works on invoices that support it</p>,
    ],
  },
  {
    version: "0.4.0",
    content: [
      <p>All addresses are now Taproot-enabled</p>,
      <p>SegWit addresses have been deprecated</p>,
      <p>
        To access SegWit funds, the old version of BitMask can be accessed here:
        https://segwit.bitmask.app/
      </p>,
      <p>Shiny new create wallet experience to help identify mistyped words</p>,
      <p>RGB assets can now be imported by genesis, but not yet by ID</p>,
      <p>Updated integration of UDAs from the DIBA marketplace</p>,
      <p>
        Latest release of bitmask-segwit lays down the groundwork for the
        DeepWeb3
      </p>,
    ],
  },
];

export const releaseNotes =
  CONTEXT === "MIXD Future Factory"
    ? [
        <p className="text-lg dark:text-gray-400 sm:text-xl lg:text-2xl">
          Welcome to The Future Factory. The future of immersive music & art.
        </p>,
      ]
    : CONTEXT === "Coinbase"
    ? [
        <p className="text-lg dark:text-gray-400 sm:text-xl lg:text-2xl">
          Coinbase-compatible BitMask Wallet
        </p>,
      ]
    : RELEASE_NOTES.find((el) => el.version === VERSION)?.content ||
      RELEASE_NOTES[0].content;

// For when we're able to show old release notes:

export const termsOfService = [
  {
    title: "Acceptance of Service Terms",
    body: (
      <p className="text-base whitespace-pre-line sm:text-lg">
        The BitMask Testnet Wallet (“Testnet Wallet”) is a self-custody wallet
        provided for testing purposes (See Section 3 below), that provides a
        web-based platform for managing your accounts of Testnet Bitcoin or
        “tSats” and any Unique Digital Assets ”UDAs”/Tokens created on Testnet.
        The Testnet Wallet enables interaction with decentralized applications
        on the Bitcoin Testnet, while keeping you in control over what
        operations you approve. The Testnet Wallet is{" "}
        <a
          href="https://chrome.google.com/webstore/detail/bitmask/momakdpclmaphlamgjcndbgfckjfpemp"
          className="underline"
        >
          provided through the Chrome Web Store
        </a>{" "}
        as a browser plugin - which includes access to text, images, audio, code
        and other materials (collectively, the “Content”), and all of the
        features, and services provided. The browser plug-in and any other
        features, tools, materials, or other services offered from time to time
        by BitMask for the Testnet Wallet are referred to here as the “Wallet
        Services”. Please read these Terms carefully before using the Wallet
        Services. By using, or otherwise accessing the Wallet Services, or
        clicking to accept, or agree to these Terms where that option is made
        available, you (1) accept and agree to these BitMask Testnet Wallet
        Terms, and (2) consent to the collection, use, disclosure and other
        handling of information as described.
      </p>
    ),
  },
  {
    title: "Account Password and Security",
    body: (
      <p className="text-base whitespace-pre-line sm:text-lg">
        When setting up an account within the Testnet Wallet you are solely
        responsible for the retention and security of your private key and your
        twelve-word mnemonic phrase (“Secret Phrase”) associated with your
        wallet. You must keep your wallet address, Secret Phrase, and private
        key access information secure. It is very important that you backup your
        private keys, backup phrases or passwords. Failure to do so may result
        in the loss of control of Digital Assets associated with your Testnet
        Wallet. You acknowledge and agree that we do not receive or store your
        Testnet Wallet password, encrypted private key, unencrypted private key,
        or Secret Phrase associated with your Testnet Wallet. We cannot generate
        a new password for your Testnet wallet if you fail to remember your
        original password. If you have not safely stored a backup of any Testnet
        Wallet address and private key pairs maintained in your Testnet Wallet,
        you accept and acknowledge that any Digital Assets you have associated
        with such Testnet Wallet address will become inaccessible.
      </p>
    ),
  },
  {
    title: "Testing Purposes",
    body: (
      <p className="text-base whitespace-pre-line sm:text-lg">
        The Testnet Wallet and the Wallet Services and Content are only for
        testing purposes in a non-production environment on Bitcoin Testnet. The
        Testnet Wallet and Wallet Services are only for managing your accounts
        of tSats and Testnet UDAs created on Bitcoin Testnet. The Testnet Wallet
        and Wallet Services should not, nor are they intended to be used: (1) on
        Bitcoin blockchain other than the Testnet; and (2) to manage any
        accounts other than that of tSats and Testnet UDAs created on Bitcoin
        Testnet. Testnet Wallet is not intended to be relied on for any reason
        whatsoever or to be used in a production environment. You acknowledge
        and accept that the Testnet Wallet and the Wallet Services (a) may
        contain bugs, errors and defects; (b) may function improperly or be
        subject to periods of downtime and unavailability; (c) may result in
        total or partial loss or corruption of data; (d) may result in partial
        or total inability to access or loss of tSats or UDAs in the Testnet
        Wallet; and (e) may be modified at any time, including through the
        release of subsequent versions, all with or without notice to you. The
        Testnet Wallet and Wallet Services are available on an “as is” basis for
        the sole purpose of collecting feedback on quality, usability,
        performance and defects.
      </p>
    ),
  },
  {
    title: "Modification of Terms of Use",
    body: (
      <p className="text-base whitespace-pre-line sm:text-lg">
        BitMask by DIBA Global INC reserves the right, at its sole discretion,
        to modify or replace these Terms with every new version release. The
        most current version of these Terms will be posted on our website. You
        shall be responsible for reviewing and becoming familiar with any such
        modifications. Use of the Wallet Services by you after any modification
        to the Terms constitutes your acceptance of the BitMask Testnet Wallet
        Terms as modified.
      </p>
    ),
  },
  {
    title: "Support",
    body: (
      <p className="text-base whitespace-pre-line sm:text-lg">
        You may contact us through{" "}
        <a href="https://t.me/tryDIBA" className="underline">
          Telegram
        </a>{" "}
        or by emailing us at info@diba.io for assistance with issues that you
        have with use of Testnet Wallet, but we do not provide any service level
        commitments with respect to our customer service, troubleshooting, or
        updates relating to these Services.
      </p>
    ),
  },
  {
    title: "Testnet Cookies",
    body: (
      <p className="text-base whitespace-pre-line sm:text-lg">
        By accessing and using Testnet Wallet, you acknowledge that you have
        read and understood the Cookies Policy, which sets out how we use
        cookies in connection with your access and use of Testnet.{" "}
        <a href="https://t.me/tryDIBA" className="underline">
          The Cookies Policy is available here.
        </a>
      </p>
    ),
  },
];

export const faqGeneral = [
  {
    question: "What is BitMask? ",
    answer: (
      <span>
        BitMask is a browser extension wallet created by the{" "}
        <a href="https://diba.io" className="underline cursor-pointer">
          DIBA
        </a>{" "}
        Team for decentralized applications on Bitcoin that enables access to
        Bitcoin Finance, UDAs, and more using the RGB protocol. BitMask provides
        full financial sovereignty with its Taproot-enabled Bitcoin and
        Lightning Network wallet, making it a gateway to DeepWeb3 on Bitcoin.
        With BitMask, users can securely and privately interact with
        decentralized applications and take custody of their finances.
      </span>
    ),
  },
  {
    question: "What is DIBA?",
    answer:
      "DIBA is the first digital assets marketplace that utilizes the RGB Smart Contract Protocol. This allows for the creation and direct transactions of Unique Digital Assets (similar to NFTs) on Bitcoin, without the use of any other tokens. RGB Smart Contracts enable the creation and management of digital assets with specific attributes, such as ownership, transferability, and divisibility, all while retaining full self-custodial ownership and control of those assets. With DIBA, users can discover, buy, and sell a wide range of unique digital assets, while leveraging the security and immutability of the Bitcoin blockchain.",
  },
  {
    question: "Is BitMask a non-custodial wallet?",
    answer:
      "Yes, BitMask is a non-custodial wallet and gives the user full control over their private keys and funds.",
  },
  {
    question:
      "What’s the difference between custodial and non-custodial wallets?",
    answer: (
      <div className="flex flex-col space-y-6">
        <p>
          A non-custodial wallet is a type of wallet that provides users with
          full control over their private keys and funds. Unlike custodial
          wallets, which rely on third-party service providers to manage the
          private keys, non-custodial wallets allow users to maintain sole
          ownership and control over their keys and digital assets.
        </p>
        <p>
          This means that users are responsible for securely storing and
          managing their own private keys, and they can access and transact with
          their funds without the need for approval or authorization from a
          third party. Non-custodial wallets are generally considered to be more
          secure and private than custodial wallets, but they also require users
          to have a greater level of knowledge and expertise in managing their
          own keys and digital assets.
        </p>
      </div>
    ),
  },
  {
    question: "Is BitMask an Open Source Wallet?",
    answer: (
      <span>
        Yes, We believe in transparency, security, and flexibility. That&#39;s
        why the source code for our core wallet functionality is publicly
        available on{" "}
        <a
          href="https://github.com/diba-io/bitmask-segwit"
          className="underline cursor-pointer"
        >
          GitHub
        </a>
        . This allows for independent auditing by security experts to ensure
        that the wallet is secure and free of vulnerabilities.
      </span>
    ),
  },

  {
    question: "What is the Lightning Network?",
    answer: (
      <div>
        <p>
          The Lightning Network is a &#34;Layer 2&#34; protocol designed to be
          layered on top of a blockchain-based cryptocurrency such as Bitcoin.
          It is intended to enable fast and cost-efficient transactions among
          participating nodes.{" "}
        </p>
        <span>
          Learn more at{" "}
          <a
            href="https://lightning.network"
            className="underline cursor-pointer"
          >
            Lightning.Network
          </a>
          .
        </span>
      </div>
    ),
  },
  {
    question: "What are RGB Smart Contracts?",
    answer: (
      <div className="flex flex-col space-y-6">
        <p>
          RGB adds a generalized smart contract protocol without the need for a
          separate sidechain or fee token. On-chain fees are paid whenever
          changing token state is anchored to specific Bitcoin outputs, such as
          transferring ownership of a token. For certain kinds of tokens,
          Lightning can be used to further reduce fees. The majority of RGB
          contract state is stored separately off-chain. DIBA is building tools
          to better manage user-encrypted RGB contract data.{" "}
        </p>
        <span>
          <a
            href="https://diba.io/blog/understanding-rgb-protocol/"
            className="underline cursor-pointer"
          >
            Read more about RGB
          </a>
          .
        </span>
      </div>
    ),
  },
];

export const faqGettingStarted = [
  {
    question: "How do I Install and Create a BitMask wallet?",
    answer: (
      <div className="flex flex-col space-y-6">
        <span>
          To get started with BitMask, simply go to{" "}
          <a href="https://bitmask.app/" className="underline cursor-pointer">
            BitMask.app
          </a>
        </span>
        <p>
          {" "}
          and click the “Continue with Web Wallet” or “Download Chrome
          Extension” button. This will take you to the Google Chrome Store to
          download the extension.
        </p>
      </div>
    ),
  },
  {
    question: "What browsers support BitMask extension?",
    answer: (
      <div>
        <span>
          Currently, the BitMask browser extension is only available on the{" "}
          <a
            href="https://chrome.google.com/webstore/detail/bitmask/momakdpclmaphlamgjcndbgfckjfpemp"
            className="underline cursor-pointer"
          >
            Chrome Web Store
          </a>
          . However, we are working on expanding support to other browsers such
          as Firefox, Edge, and Safari in the near future.
        </span>
      </div>
    ),
  },
  {
    question: "Do I need to create a Lightning Network (LN) wallet separately?",
    answer: (
      <div className="flex flex-col space-y-6">
        <p>
          No, you don&#39;t need to create a separate LN wallet when using
          BitMask. Wallet secrets, such as Lightning keys, are encrypted and
          synced using a key generated by your BitMask wallet.
        </p>
        <p>
          This means that you can access both Bitcoin and Lightning funds within
          a single wallet interface. With BitMask, users can easily switch
          between Bitcoin and Lightning Network channels, making it a convenient
          and seamless experience.
        </p>
      </div>
    ),
  },
  {
    question: "Do I need Bitcoin to start using BitMask?",
    answer: "Yes, BitMask is a Bitcoin only wallet.",
  },
  {
    question:
      "What is the difference between Testnet and Mainnet? And how do I switch between them?",
    answer: (
      <div className="flex flex-col space-y-6">
        <p>
          Testnet and Mainnet are two distinct networks within the Bitcoin
          ecosystem, each serving different purposes.
        </p>
        <ol className="list-disc list-inside space-y-6">
          <li>
            Testnet: Testnet is a network specifically designed for developers
            and users to experiment, test, and debug their applications without
            using real Bitcoin. Testnet coins, also known as test sats, have no
            financial value and can be freely obtained from faucets or other
            sources. Testnet allows developers to ensure the functionality and
            compatibility of their software before deploying it on the live
            Mainnet.
          </li>
          <li>
            Mainnet: Mainnet is the actual Bitcoin network where real financial
            transactions occur. Bitcoin on Mainnet holds monetary value and can
            be bought, sold, and used for everyday transactions. It is the live
            network used by individuals and businesses for real-world
            transactions and investments.
          </li>
        </ol>
        <p>
          Switching between Testnet and Mainnet wallets in BitMask is simple:
        </p>
        <ol className="ml-6 list-decimal list-inside space-y-6">
          <li>Open your BitMask wallet.</li>
          <li>
            Look for a button in the top right corner of the interface. This
            button allows you to switch between Testnet and Mainnet.
          </li>
          <li>
            By default, your BitMask wallet is set to the Mainnet. If you want
            to switch to Testnet, click on the button to toggle and select the
            Testnet option.
          </li>
          <li>
            Conversely, if you wish to switch back to the Mainnet, click the
            button again and select the Mainnet option.
          </li>
        </ol>
        <p>
          It&#39;s important to remember that while using Testnet, the coins you
          handle are only for testing purposes and do not hold any real
          financial value. Be cautious and ensure that you are operating on the
          appropriate network based on your needs.
        </p>
      </div>
    ),
  },
  {
    question: "How do I transfer my existing Bitcoin and assets into BitMask?",
    answer:
      "Open your BitMask wallet and click on the 'Receive' button to see the list of options. You can choose to receive Bitcoin on-chain, receive Bitcoin through Lightning, or receive an asset. Read more on how to send & receive Bitcoin in the Payments FAQ section.",
  },
  {
    question:
      "Can I transfer my assets/tokens on Stacks, Counterparty, Rootstock, Liquid, OmniLayer, etc., into BitMask?",
    answer: (
      <div className="flex flex-col space-y-6">
        <p>
          Currently, BitMask only supports Bitcoin and Lightning Network
          transactions. We do not yet support alternate Bitcoin-native token
          implementations, such as those on Stacks, Counterparty, Rootstock,
          Liquid, OmniLayer, and others.
        </p>
        <p>
          However, we are planning to support alternate token implementations in
          the future using federated token bridging. This will allow for the
          transfer of tokens across different Bitcoin-based networks without the
          need for a central exchange.
        </p>
      </div>
    ),
  },
  {
    question: "What are the supported platforms and devices for the wallet?",
    answer: (
      <div className="flex flex-col space-y-6">
        <p>
          The BitMask wallet is currently available as a browser extension for
          the Google Chrome browser on desktop devices. We are planning to
          support more browsers in the future, including Firefox, Edge, and
          Safari.
        </p>
        <p>
          As for mobile devices, we are currently working on a mobile app
          version of the wallet that will be available for both iOS and Android.
          We are also planning to support other hardware devices in the near
          future.
        </p>
        <span>
          Additionally, BitMask is working to integrate with other platforms and
          services in the Bitcoin ecosystem.{" "}
          <a className="underline cursor-pointer" href="https://diba.io">
            DIBA
          </a>{" "}
          is currently the first and only RGB-supported digital assets
          marketplace platform that is integrated with the BitMask wallet. We
          are constantly working to add more supported platforms and devices, so
          please stay tuned for updates.
        </span>
      </div>
    ),
  },
  {
    question: "Can I use BitMask to manage multiple cryptocurrencies?",
    answer: (
      <div className="flex flex-col space-y-6">
        <p>
          BitMask only supports Bitcoin as its primary blockchain. The wallet is
          designed to provide users with a secure and user-friendly interface
          for managing their Bitcoin funds, including the ability to send and
          receive Bitcoin on-chain and via Lightning Network.
        </p>
        <p>
          At some point, we also plan to introduce a token bridging solution
          that will help users bring tokens from other chains onto RGB, allowing
          them to be managed and traded using the BitMask wallet.
        </p>
      </div>
    ),
  },
];

export const faqPayments = [
  {
    question: "What is my wallet address?",
    answer:
      "Your wallet address is a string of alphanumeric characters that serves as a destination for receiving cryptocurrencies. In BitMask, you can find your public Bitcoin address on top of your wallet or by clicking on the 'Receive' button and choosing to receive Bitcoin on the chain.",
  },
  {
    question: "How do I receive payments with Lightning?",
    answer: (
      <div className="flex flex-col space-y-6">
        <p>
          BitMask creates Lightning Network (LN) invoices, which are typically a
          series of letters and numbers that allow others to send payments
          accessible to your wallet. To find your LN address in BitMask, follow
          these steps:
        </p>
        <ol className="list-decimal list-inside space-y-6">
          <li>Open the BitMask wallet.</li>
          <li>
            Click on &#34;Receive&#34; and choose to “Receive Bitcoin through
            Lightning” to generate an LN invoice.
          </li>
          <li>
            Your LN invoice will be displayed as QR code as well as a long
            string of letters and numbers, usually starting with &#34;lnbc&#34;.
          </li>
        </ol>
        <p>
          Note that your LN invoice will change each time you generate one. It
          is recommended to generate a new address for each transaction to
          enhance privacy and security.
        </p>
      </div>
    ),
  },
  {
    question: "How do I receive and send Bitcoin using my wallet?",
    answer: (
      <div className="flex flex-col space-y-6">
        <p>
          To receive or send Bitcoin with your BitMask wallet, follow these
          steps:
        </p>
        <ol className="list-decimal list-inside space-y-6">
          <li>
            Open your BitMask wallet and look for the &#34;Send&#34; or
            &#34;Receive&#34; buttons.
          </li>
          <li>
            Press the appropriate button depending on whether you want to send
            or receive Bitcoin.
          </li>
          <li>
            You can choose to receive/send Bitcoin on-chain, receive/send
            Bitcoin through Lightning, or receive/send an asset. Select the
            option that best suits your needs.
          </li>
          <li>
            If you want to receive Bitcoin, share your wallet address with the
            sender. You can copy your wallet address from the &#34;Receive&#34;
            screen or use a QR code to share it.
          </li>
        </ol>
      </div>
    ),
  },
  {
    question: "How do I get funds (Bitcoin) into BitMask?",
    answer: (
      <div className="flex flex-col space-y-6">
        <p>
          Transferring your existing Bitcoin and assets into BitMask requires a
          compatible exchange or wallet that supports Taproot transactions.
          BitMask is a taproot wallet, which means it utilizes the taproot
          upgrade for enhanced privacy and efficiency.
        </p>
        <p>To transfer your Bitcoin into BitMask, follow these steps:</p>
        <ul className="list-decimal list-inside space-y-6">
          <li>
            Ensure that the exchange or wallet you are using to hold your
            Bitcoin supports Taproot transactions. If it does, proceed to the
            next step. If not, you may need to transfer your Bitcoin to a
            compatible wallet or exchange before moving them into BitMask.
          </li>
          <li>
            Open your BitMask wallet and click on the &#34;Receive&#34; button.
            This will display a list of options for receiving funds.
          </li>
          <li>
            Choose the appropriate option based on your needs:
            <ul className="mt-6 ml-6 list-disc list-inside space-y-6">
              <li>
                To receive Bitcoin on-chain: Select the option for receiving
                Bitcoin on the blockchain. This generates a Bitcoin address
                specific to your BitMask wallet. Provide this address to the
                exchange or wallet from which you wish to transfer your Bitcoin.
                Once the transaction is confirmed on the blockchain, the Bitcoin
                will be credited to your BitMask wallet.
              </li>
              <li>
                To receive Bitcoin through Lightning: If you prefer to use the
                Lightning Network for faster and cheaper Bitcoin transactions,
                select the option for receiving Bitcoin through Lightning. This
                will provide you with a Lightning invoice or payment request
                that you can share with the sending party. Once the payment is
                made and verified on the Lightning Network, the Bitcoin will be
                added to your BitMask wallet.
              </li>
            </ul>
          </li>
          <li>
            Share the provided address or payment request with the sending
            party, whether it&#39;s an exchange, another wallet, or a Lightning
            Network node. They will initiate the transfer to the provided
            address.
          </li>
          <li>
            After the transfer is completed, you will see the balance of your
            Bitcoin or assets in your BitMask wallet.
          </li>
        </ul>
        <p>
          Remember, it is crucial to ensure that the exchange or wallet you are
          using to transfer your Bitcoin supports taproot transactions, as
          BitMask relies on this upgrade for its functionality.
        </p>
      </div>
    ),
  },
];

export const faqSecurity = [
  {
    question: "How do I keep my wallet safe?",
    answer: (
      <div className="flex flex-col space-y-6">
        <p>To keep your wallet safe, you should follow these best practices:</p>
        <ol className="list-decimal list-inside space-y-6">
          <li>Keep your mnemonic seed phrase in a safe and secure place.</li>
          <li>
            Use a strong password to encrypt your wallet and never share it with
            anyone.
          </li>
          <li>
            Always verify the recipient address before sending any funds, and
            use QR codes when possible.
          </li>
          <li>
            Keep your device and software up-to-date with the latest security
            patches and updates.
          </li>
          <li>
            Be cautious of phishing scams and suspicious emails or messages.
          </li>
          <li>
            Only use trusted and reputable cryptocurrency exchanges and
            services.
          </li>
          <li>Try to keep it simple. Bitcoin is complicated enough. </li>
        </ol>
      </div>
    ),
  },
  {
    question: "How do I import my wallet into BitMask?",
    answer: (
      <div className="flex flex-col space-y-6">
        <p>
          To backup and import your wallet in BitMask, you can follow these
          steps:
        </p>
        <ol className="list-decimal list-inside space-y-6">
          <li>Click on &#34;Import wallet&#34;.</li>
          <li>
            Create a new password and write down your mnemonic phrase as a
            sentence, without numbers, in the order it was given, and with a
            space in between each word.
            <ul className="mt-6 ml-6 list-disc list-inside space-y-6">
              <li>For example: &#34;clock boat sunshine&#34;.</li>
              <li>
                In case you have doubts on any word, refer to the list of words.
              </li>
            </ul>
          </li>
          <li>
            Make sure to keep your mnemonic phrase secure and accessible only to
            you, as it is the key to your wallet&#39;s funds and assets.
          </li>
          <li>
            Should you lose your seed phrase or forget to write it down, you can
            use your password to retrieve it from the Settings menu.
          </li>
        </ol>
      </div>
    ),
  },
  {
    question: "Why do I need to create a password for BitMask?",
    answer:
      "You need to create a password for BitMask to ensure that your wallet is protected from unauthorized access. The password is used to encrypt and protect your private keys and sensitive information stored in the wallet. Without a strong password, anyone with access to your device could potentially gain access to your wallet and steal your funds. Therefore, creating a strong and unique password is crucial to ensure the security of your BitMask wallet.      ",
  },
  {
    question:
      "How does BitMask manage extended public keys and private keys to ensure security?",
    answer: (
      <div className="flex flex-col space-y-6">
        <p>
          BitMask uses XChaCha20-Poly1305 encryption for storing wallet
          descriptor data used to derive accounts from the mnemonic seed phrase.
          We also use the state-of-the-art Argon2id cryptographic password
          hashing function, which is much more difficult to brute force attack
          than SHA-256.
        </p>
        <p>
          An extended public key (also known as an xpub) can be used to generate
          a user&#39;s addresses, and is often used in watch-only wallets.
          It&#39;s unique for every wallet seed, and is also useful to
          differentiate between wallets. We use a hash of the xpub, an xpubkh,
          as a wallet identifier when associated within DeepWeb3 apps. Since
          it&#39;s a hash, it does not hold any other useful information other
          than it being unique to each wallet.
        </p>
      </div>
    ),
  },
  {
    question: "Can a Bitcoin wallet extension be hacked?",
    answer: (
      <div className="flex flex-col space-y-6">
        <p>
          If you lose your mnemonic seed phrase or your encryption passphrase,
          it is not possible to recover your wallet or access your funds. This
          is because the mnemonic seed phrase is used to generate all the keys
          in your wallet, and the encryption passphrase is used to protect your
          mnemonic seed phrase.
        </p>
        <p>
          Therefore, it is essential to keep your mnemonic seed phrase and
          encryption passphrase in a safe place and never share them with anyone
          else. If you have lost your mnemonic seed phrase or encryption
          passphrase, unfortunately, there is no way to recover your funds.
        </p>
      </div>
    ),
  },
  {
    question: "How do I recover my wallet if I lose my mnemonic seed phrase?",
    answer: (
      <div className="flex flex-col space-y-6">
        <p>
          Yes, a Bitcoin wallet extension can be vulnerable to hacking, just
          like any other software.
        </p>
        <p>
          However, reputable wallet extensions, such as ours (BitMask), have
          implemented various security measures to minimize the risk of hacking.
          These measures include encryption of private keys and using
          open-source code for transparency and independent auditing.
        </p>
        <p>
          To further reduce the risk of hacking, it is important to practice
          good security hygiene. This includes using a strong and unique
          password, keeping your software and security systems up to date, and
          avoiding suspicious links and downloads.
        </p>
        <p>
          It&#39;s also important to be wary of phishing scams and other
          attempts to steal your private information. Always double-check the
          URL of the website you are visiting and never enter your private
          information unless you are absolutely certain you are on a legitimate
          site.
        </p>
      </div>
    ),
  },
  {
    question: "Lost my password: How do I restore my wallet?",
    answer:
      "You can import your wallet as long as you have your seed phrase. Upon import you can create a new password",
  },
];

export const faqTroubleshooting = [
  {
    question: "How do I update my wallet or access the latest version?",
    answer: (
      <div>
        <p>
          The BitMask wallet updates are usually done automatically. However, if
          you haven&#39;t received an update, you can try removing the existing
          BitMask extension from your browser and downloading the latest version
          from the Google Chrome Web Store. If using the web wallet, try
          refreshing the page. In some cases, a “hard refresh” is needed; on
          Mac, this can be done through Command+Shift+R. On other operating
          systems, it’s often, ctrl-F5. This ensures that you have the latest
          features, bug fixes, and security patches. You can check the BitMask
          GitHub repository for information on the latest releases and features.
        </p>
      </div>
    ),
  },
  {
    question: "Why hasn’t my balance updated?",
    answer: (
      <div className="flex flex-col space-y-6">
        <p>
          If your wallet balance hasn&#39;t updated, it could be because the
          wallet is not updating automatically for some reason.
        </p>
        <p>
          In this case, you can manually refresh the wallet by clicking on the
          arrows button in the top right corner of the wallet. If this does not
          update your balance, you may want to check mempool.space and search
          for your transaction there (with your transaction ID) and also see the
          state of the network and transactions mempool general state.
        </p>
      </div>
    ),
  },
  {
    question:
      "What should I do if I get stuck at a loading spinner screen in BitMask?",
    answer: (
      <div>
        <p>
          If you encounter a prolonged spinning screen while using BitMask, it
          could indicate an issue with the extension or a temporary glitch. Here
          are some steps you can take to resolve the problem:
        </p>
        <ol className="mt-6 list-disc list-inside space-y-6">
          <li>
            Refresh the page: First, try refreshing the page where BitMask is
            running. This can often resolve minor glitches and bring the
            application back to a functional state. Simply click the refresh
            button on your web browser or press the F5 key or Command-R on Mac.
          </li>
          <li>
            Clear your browser cache: If refreshing the page doesn&#39;t work,
            clearing your browser cache might help. The cache stores temporary
            data and files from websites, and occasionally conflicts or outdated
            information can cause issues. Access your browser settings, find the
            option to clear cache, and then restart your browser before
            attempting to use BitMask again. Note: This only applies to the
            BitMask web wallet.
          </li>
          <li>
            Check for extension updates: Ensure that your BitMask extension is
            up to date. Developers often release updates to address bugs and
            improve performance. Visit the extension store for your web browser
            and look for any available updates for BitMask. If an update is
            available, install it and relaunch the extension.
          </li>
          <li>
            Remove and reinstall the extension: If the spinning screen persists,
            you may need to remove the BitMask extension from your browser and
            reinstall it. Here&#39;s how:
            <ol className="mt-6 ml-6 list-decimal list-inside space-y-6">
              <li>Go to your browser&#39;s settings or extensions menu.</li>
              <li>
                Find the BitMask extension and select the option to remove or
                uninstall it.
              </li>
              <li>Once the extension is removed, restart your browser. </li>
              <li>
                Visit the official BitMask website or the extension store for
                your browser to download and reinstall BitMask.
              </li>
              <li>
                Follow the installation instructions provided by the extension
                to set it up again. Contact BitMask support: If you&#39;ve tried
                the above steps and continue to experience the spinning screen
                issue, it&#39;s advisable to reach out to the BitMask support
                team via Telegram.
              </li>
            </ol>
          </li>
        </ol>
      </div>
    ),
  },
  {
    question: "I found a bug, what should I do?",
    answer: (
      <div className="flex flex-col space-y-6">
        <p>
          If you encounter a bug while using the BitMask wallet, please let us
          know as soon as possible so we can address the issue. You can report
          bugs by contacting us through Telegram or by sending an email to
          tech@diba.io.
        </p>
        <p>
          Please provide as much information as possible, such as the steps you
          took leading up to the issue, any error messages you received, and
          your device and operating system information. This will help us
          identify and fix the problem as quickly as possible. Thank you for
          helping us improve the BitMask wallet!
        </p>
      </div>
    ),
  },
  {
    question: "I can’t import a token by RGB ID, what should I do?",
    answer: (
      <div className="flex flex-col space-y-6">
        <p>
          If you can&#39;t import a token by its RGB ID into BitMask, it&#39;s
          because it&#39;s not yet in our system. In this case, you will need to
          obtain the asset genesis and import the token manually.
        </p>
        <p>
          However, please note that all token contracts that have been
          previously loaded onto BitMask have their RGB IDs added to our
          database for our users convenience. So, if you are having trouble
          importing a token by its RGB ID, it may be because the token is newly
          issued or not yet supported by BitMask. In such cases, you will need
          to obtain the asset genesis to import the token manually.
        </p>
      </div>
    ),
  },
];

export const ifaceUdas = "RGB21";
export const ifaceFungibles = "RGB20";

export const tapretPrefix = "tapret1st";
export const terminalBtc = "/1/1";
export const terminalChangeTapret = "/10/1";
export const terminalChangeOpret = "/9/1";
