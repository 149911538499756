/* eslint-disable array-callback-return */
/* eslint-disable jsx-a11y/media-has-caption */
/* eslint-disable no-console */
/* eslint-disable no-nested-ternary */
/* global chrome */

import React, { useState, useEffect, useRef, useContext } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import { Disclosure } from "@headlessui/react";
import { ChevronUpIcon } from "@heroicons/react/24/solid";
import * as backend from "bitmask-segwit";
import { Activity, WalletData } from "bitmask-segwit/bitcoin";
import { LnWalletData } from "bitmask-segwit/lightning";
import { CARBONADO, LNDHUBX, init } from "bitmask-segwit/constants";
import { TEST_IDS } from "tests/ids";

import { MainNavContext } from "src/Hooks/MainNavContext";
import Copy from "src/Components/Icons/Copy";
import Tabs from "src/Components/Tabs/Tabs";
import ChevronLeft from "src/Components/Icons/ChevronLeft";
import CopiedToClipboard from "src/Components/Modals/CopiedToClipboard";
import Modal from "src/Components/Modals/Modal";
import ReleaseNotes from "src/Components/Modals/Children/ReleaseNotes";
import Loader from "src/Components/Loaders/Loader";
import {
  getConversion,
  classNames,
  addBalance,
  getLargeNumber,
} from "src/Hooks/util";
import lib, { isExtension } from "src/lib";
import FundAssets from "src/Components/Modals/Children/FundAssets";
import { Asset, Collection, Location } from "src/types";
import { ifaceFungibles, ifaceUdas, releaseNotes } from "src/constants";
import AssetFooter from "src/Components/Layout/Footers/AssetFooter";
import Banner from "src/Components/Modals/Banner";
import { importWallet } from "src/Pages/WalletImport";
import ErrorModal from "src/Components/Modals/Error";
import { ContractResponse, NextUtxoResponse } from "bitmask-segwit/rgb";
import { AssetView } from "src/types/views";
import IconAndLabelButton from "src/Components/Buttons/IconAndLabelButton";
import ArrowUp from "src/Components/Icons/ArrowUp";
import ArrowDown from "src/Components/Icons/ArrowDown";
import Swap from "src/Components/Icons/Swap";
import RgbAsset from "src/Components/Icons/RgbAsset";
import SendLightning from "src/Components/Icons/SendLightning";
import ReceiveLightning from "src/Components/Icons/ReceiveLightning";
import SendBitcoin from "src/Components/Icons/SendBitcoin";
import ReceiveBitcoin from "src/Components/Icons/ReceiveBitcoin";
import Balances from "src/Components/MainWallet.tsx/Balances";
import SwapCircle from "src/Components/Icons/SwapCircle";
import CoinCircle from "src/Components/Icons/CoinCircle";

const sortActivitiesData = (
  data: WalletData,
  lnActivities: backend.bitcoin.Activity[] | null
) => {
  if (!data?.transactions) {
    return [];
  }
  const layerOneActivitiesData = data.transactions.map((transaction) => {
    let date = Math.round(Date.now() / 1000);
    if (transaction.confirmationTime !== null)
      date = transaction.confirmationTime.timestamp;
    const activity: Activity = {
      ...transaction,
      date,
      id: transaction.txid,
      assetType: "sats",
      amount:
        transaction.sent === 0
          ? transaction.received
          : transaction.sent - transaction.received - transaction.fee,
      fee: transaction.fee,
      action: transaction.sent === 0 ? "received" : "sent",
      status: transaction.confirmed ? "confirmed" : "pending",
    };
    return activity;
  });
  const sortedActivities = [...layerOneActivitiesData];
  if (lnActivities) {
    sortedActivities.push(...lnActivities);
  }
  sortedActivities.sort((a, b) => {
    if (a.date > b.date) return -1;
    if (a.date < b.date) return 1;
    return 0;
  });

  return sortedActivities;
};

const Wallet = () => {
  const navigate = useNavigate();
  const location = useLocation() as Location;
  const { wallet, vault, lnCredentials, hash } = location?.state ?? {};

  let { network } = location?.state ?? {};

  if (!network) {
    network = localStorage.getItem("network") || "bitcoin";
  }

  const { setMainNav } = useContext(MainNavContext);
  const [loading, setLoading] = useState(false);
  const [loadingBitcoin, setLoadingBitcoin] = useState(false);
  const [loadingLightning, setLoadingLightning] = useState(false);
  const [loadingActivities, setLoadingActivities] = useState(false);
  const [loadingAssets, setLoadingAssets] = useState(false);
  const [loadingUda, setLoadingUda] = useState(false);
  const [loadingVault, setLoadingVault] = useState(true);
  const [tokensWalletAddress, setTokensWalletAddress] = useState("");
  const [tokensWalletFunded, setTokensWalletFunded] = useState(false);
  const [udasWalletAddress, setUdasWalletAddress] = useState("");
  const [openFundAssetsModal, setOpenFundAssetsModal] = useState(false);
  const [view, setView] = useState(0);
  const [openVersionPopup, setOpenVersionPopup] = useState(false);
  const [conversion, setConversion] = useState(0);
  const [openCopyModal, setOpenCopyModal] = useState(false);
  const [openBanner, setOpenBanner] = useState(false);
  const [assetUtxos, setAssetUtxos] = useState({
    currentUtxoTokens: "",
    currentUtxoUdas: "",
  });
  const [walletData, setWalletData] = useState<WalletData>(
    localStorage.getItem(`${network}WalletData`)
      ? JSON.parse(localStorage.getItem(`${network}WalletData`) || "")
      : []
  );
  const [walletAddress, setWalletAddress] = useState("");
  const [lnWalletData, setLnWalletData] = useState<LnWalletData>();
  const [activities, setActivities] = useState<Activity[]>();
  const [assets, setAssets] = useState<Asset[]>(
    localStorage.getItem(`${network}Assets`)
      ? JSON.parse(localStorage.getItem(`${network}Assets`) || "")
      : []
  );
  const [collections, setCollections] = useState<Collection[]>(
    localStorage.getItem(`${network}Udas`)
      ? JSON.parse(localStorage.getItem(`${network}Udas`) || "")
      : []
  );
  const [displayedError, setError] = useState({
    title: "",
    message: "",
  });
  const [openError, setOpenError] = useState(false);
  const [pendingAssetTransfers, setPendingAssetTransfers] = useState(true);
  const [pendingUdaTransfers, setPendingUdaTransfers] = useState(true);
  const [fundingPending, setFundingPending] = useState(false);

  const ref = useRef<HTMLDivElement>(null);

  const { VERSION, CONTEXT = "" } = process.env;

  // Storage accessors
  const walletDataKey = `${network}.wallet.bitcoin`;
  const lnWalletKey = `${network}.LnWalletData`;
  const txHistoryKey = `${network}.TransactionHistory`;
  const assetsKey = `${network}.Assets`;
  const udasKey = `${network}.Udas`;
  const udasWalletDataKey = `${network}.wallet.udas`;

  const storeWalletData = async (newWalletData: WalletData) => {
    setWalletData(newWalletData);
    console.debug("storing Bitcoin wallet data", newWalletData);
    await lib.storageSet({ [walletDataKey]: newWalletData });
    setLoadingBitcoin(false);
  };

  const retrieveWalletData = async (): Promise<WalletData> => {
    const result = (await lib.storageGet<WalletData>([walletDataKey]))[
      walletDataKey
    ];
    if (result) setWalletData(result);
    return result;
  };

  const storeLnWalletData = async (newLnWalletData: LnWalletData) => {
    setLnWalletData(newLnWalletData);
    console.debug("storing LN wallet data", newLnWalletData);
    await lib.storageSet({ [lnWalletKey]: newLnWalletData });
    setLoadingLightning(false);
  };

  const retrieveLnWalletData = async (): Promise<LnWalletData> => {
    const result = (await lib.storageGet<LnWalletData>([lnWalletKey]))[
      lnWalletKey
    ];
    if (result) setLnWalletData(result);
    return result;
  };

  const storeActivities = async (newActivities: Activity[]) => {
    setActivities(newActivities);
    console.debug("storing wallet tx data", newActivities);
    await lib.storageSet({
      [txHistoryKey]: newActivities,
    });
    setLoadingActivities(false);
  };

  const retrieveActivities = async (): Promise<Activity[]> => {
    const result = (await lib.storageGet<Activity[]>([txHistoryKey]))[
      txHistoryKey
    ];
    if (result) setActivities(result);
    return result;
  };

  const storeAssets = async (newAssets: Asset[]) => {
    setAssets(newAssets);
    console.debug("storing assets data", newAssets);
    await lib.storageSet({
      [assetsKey]: newAssets,
    });
    setLoadingAssets(false);
  };

  const retrieveAssets = async (): Promise<Asset[]> => {
    const result = (await lib.storageGet<Asset[]>([assetsKey]))[assetsKey];
    if (result) setAssets(result);
    return result;
  };

  const storeUdas = async (newUdas: Collection[]) => {
    setCollections(newUdas);
    console.debug("storing UDAs data", newUdas);
    await lib.storageSet({
      [udasKey]: newUdas,
    });
    setLoadingUda(false);
  };

  const retrieveUdas = async (): Promise<Collection[]> => {
    const result = (await lib.storageGet<Collection[]>([udasKey]))[udasKey];
    if (result) setCollections(result);
    console.log(result, "collections");
    return result;
  };

  const getAssetUtxos = async () => {
    let currentUtxoTokens = "";
    let currentUtxoUdas = "";
    const { funded } = await lib.storageGet(["funded"]);

    const avalaibleUtxo: NextUtxoResponse = await backend.rgb.nextUtxo(
      vault.private.nostrPrv,
      "rgb20"
    );
    const isFunded = !!avalaibleUtxo.utxo;

    if (avalaibleUtxo.utxo) {
      currentUtxoTokens = avalaibleUtxo.utxo;
      currentUtxoUdas = avalaibleUtxo.utxo;
    }

    console.log("fund vault details", {
      funded,
      fungibleUtxo: currentUtxoTokens,
      udaUtxo: currentUtxoUdas,
    });

    if (isFunded) {
      setLoadingVault(false);
      setTokensWalletFunded(true);
      setFundingPending(false);
    } else if (funded && !isFunded) {
      setLoadingVault(false);
      setTokensWalletFunded(true);
      setFundingPending(true);
    } else {
      setLoadingVault(false);
      setTokensWalletFunded(false);
      setFundingPending(true);
    }

    const currentUtxos = {
      currentUtxoTokens,
      currentUtxoUdas,
    };
    setAssetUtxos(currentUtxos);
  };

  /* eslint-disable */
  const getAddresses = async () => {
    const addressBitcoin = await backend.bitcoin.getNewAddress(
      vault.public.btcDescriptorXpub
    );
    setWalletAddress(addressBitcoin);
    const { address: addresTokens } = await backend.rgb.nextAddress(
      vault.private.nostrPrv,
      "rgb20"
    );
    setTokensWalletAddress(addresTokens);
    const { address: addresUdas } = await backend.rgb.nextAddress(
      vault.private.nostrPrv,
      "rgb21"
    );
    setUdasWalletAddress(addresUdas);
  };

  // Error handling
  const errorCompensation = async (error: Error, context: string) => {
    console.debug(
      "entering error compensation for",
      error,
      "in context",
      context
    );
    if (
      error?.toString().toLowerCase().includes("invalid network") ||
      error?.toString().toLowerCase().includes("missing field `uid`")
    ) {
      console.debug("compensating by reimporting the wallet");
      try {
        const mainnetDescriptors =
          window.localStorage.getItem("bitcoinDescriptor");
        const newVault = await backend.bitcoin.decryptWallet(
          hash,
          mainnetDescriptors as string
        );

        const isRecoveryState = localStorage.getItem("isRecoveryState");
        localStorage.setItem("isRecoveryState", "in recovery");

        await importWallet({
          hash,
          words: newVault.mnemonic,
          navigate,
          reload: !isRecoveryState,
        });
      } catch (innerError) {
        console.error("failed to compensate", innerError);
        localStorage.removeItem("isRecoveryState");
        setLoading(false);
        setOpenError(true);
        setError({
          title:
            (innerError as Error)?.name ||
            `Unhandled exception in ${context} error compensation`,
          message:
            (innerError as Error)?.message ||
            innerError?.toString() ||
            `Unhandled exception in ${context} error compensation`,
        });
      }
    } else {
      setError({
        title: `Unhandled exception in ${context}`,
        message:
          error?.message ||
          error?.toString() ||
          `Unhandled exception in ${context}`,
      });
      setOpenError(true);
    }
  };

  // LN
  const lnAuth = async () => {
    if (
      lnCredentials?.login?.length > 0 &&
      lnCredentials?.password?.length > 0
    ) {
      const tokens = await backend.lightning.auth(
        lnCredentials.login,
        lnCredentials.password
      );
      return tokens;
    }
    throw new Error("Not logged in");
  };

  const getLightningWalletData = async () => {
    console.debug("getLightningWalletData called");
    try {
      const tokens = await lnAuth();
      let token = "";

      if ("error" in tokens && tokens.error === "UserDoesNotExist") {
        console.warn("Handling UserDoesNotExist in getLightningWalletData...");
        await backend.lightning.createWallet(
          vault.public.xpubkh,
          vault.private.xprvkh
        );
        const retryTokens = await backend.lightning.auth(
          vault.public.xpubkh,
          vault.private.xprvkh
        );
        if ("error" in retryTokens) {
          console.error(
            "Error Authorizing Lightning Wallet",
            retryTokens.error
          );
          setError({
            title: "Error Authorizing Lightning Wallet (retry)",
            message: retryTokens.error,
          });
          setOpenError(true);
          return null;
        }
        token = retryTokens.token;
      } else if ("error" in tokens) {
        console.error(
          "Error Authorizing Lightning Wallet (outer)",
          tokens.error
        );
        setError({
          title: "Error Authorizing Lightning Wallet",
          message: tokens.error,
        });
        setOpenError(true);
        return null;
      } else {
        token = tokens.token;
      }

      const lnAccount = await backend.lightning.getBalance(token);
      const lnTxs = await backend.lightning.getTxs(token);

      console.debug("getLightningWalletData results", { lnAccount, lnTxs });

      const lnActivitiesData = lnTxs.map((transaction) => {
        let date = Math.round(Date.now() / 1000);
        if (transaction.created_at !== null)
          date = Number(transaction.created_at) / 1000;
        const activity: Activity = {
          ...transaction,
          lightning: true,
          date,
          id: transaction.txid,
          assetType: "sats",
          amount:
            (Number(transaction.outbound_amount) - Number(transaction.fees)) *
            100000000,
          fee: Number(transaction.fees) * 100000000,
          action:
            transaction.outbound_account_id === lnAccount[0].account_id
              ? "sent"
              : "received",
          status: "confirmed",
          received: 0,
          sent: 0,
          confirmed: false,
          confirmationTime: {
            height: 0,
            timestamp: 0,
          },
          vsize: 0,
          feeRate: 0,
        };
        return activity;
      });
      await storeLnWalletData({
        balance: lnAccount[0],
        transactions: lnTxs,
      });
      return lnActivitiesData;
    } catch (error) {
      console.error(error, "ln error");
      if (error?.toString().includes("/auth")) {
        try {
          // Error is likely caused by account not present on LNDHubX
          await backend.lightning.createWallet(
            vault.public.xpubkh,
            vault.private.xprvkh
          );
          return getLightningWalletData();
        } catch (err) {
          console.error(
            "error attempting to create an account in getLightningWalletData catch",
            err
          );
          localStorage.setItem("isRecoveryState", "in recovery");
          await errorCompensation(error as Error, "getLightningWalletData");
          return null;
        }
      }
      localStorage.setItem("isRecoveryState", "in recovery");
      await errorCompensation(error as Error, "getLightningWalletData");
      return null;
    }
  };

  // Bitcoin
  /* eslint-disable */
  const getWalletData = async () => {
    console.debug("getWalletData called");
    setLoadingBitcoin(true);
    setLoadingLightning(true);
    setLoadingActivities(true);
    let lnActivities: backend.bitcoin.Activity[] | null = null;
    if (LNDHUBX) {
      lnActivities = await getLightningWalletData();
      console.debug("getLightningWalletData success!");
    } else {
      console.debug(
        "skipping getLightningWalletData due to LNDHUBX being false"
      );
    }
    if (window.localStorage.getItem("loadNetworkChange")) {
      setLoading(true);
    }
    const walletData = await retrieveWalletData();

    if (walletData) {
      await storeActivities(sortActivitiesData(walletData, lnActivities));
    }

    const encryptedDescriptors = window.localStorage.getItem(
      `${network}Descriptor`
    );
    const getWalletDataVault = await backend.bitcoin.decryptWallet(
      hash,
      encryptedDescriptors as string
    );
    const { btcDescriptorXprv, btcChangeDescriptorXprv } =
      getWalletDataVault.private;

    console.debug("getWalletData try block");
    try {
      const response = await backend.bitcoin.getWalletData(
        btcDescriptorXprv,
        btcChangeDescriptorXprv
      );
      const data = { ...response, name: wallet };
      console.debug("getWalletData data", data);
      await storeWalletData(data);
      console.debug("Wallet xpubkh:", vault.public.xpubkh);

      await storeActivities(sortActivitiesData(data, lnActivities));

      if (window.localStorage.getItem("loadNetworkChange") === "true") {
        window.localStorage.removeItem("loadNetworkChange");
      }
      setLoading(false);
      // console.debug(`sortedActivities: ${JSON.stringify(sortedActivities)}`);
    } catch (error) {
      await errorCompensation(error as Error, "getWalletData");
    }
  };

  // RGB tokens
  /* eslint-disable */
  const getNewAssets = async () => {
    setLoadingAssets(true);
    setLoadingUda(true);
    if (!CARBONADO) return;
    await retrieveAssets();
    await retrieveUdas();

    try {
      const { data: contracts } = await backend.rgb.listContracts(
        vault.private.nostrPrv,
        false
      );

      const newAssets = contracts.filter(
        (contract) => contract.iface === ifaceFungibles
      );

      console.debug("fungibles", newAssets);
      await storeAssets(newAssets);

      const newUdas = contracts.filter(
        (contract) =>
          contract.iface === ifaceUdas &&
          contract.balance.uda &&
          contract.balance.uda.fraction > 0
      );

      const udaCollections = {};

      newUdas.forEach((collection) => {
        const { ticker } = collection;

        if (!udaCollections[ticker]) {
          udaCollections[ticker] = [];
        }
        udaCollections[ticker].push(collection);
      });

      const collectionArray: Collection[] = Object.entries(udaCollections).map(
        (ele) => ({
          name: ele[0] === "DIBAUDA" ? "no collection" : ele[0],
          id: ele[0],
          udas: ele[1] as ContractResponse[],
        })
      );

      const newCollections: Collection[] = collectionArray;

      await storeUdas(newCollections);
    } catch (error) {
      await errorCompensation(error as Error, "getNewAssets");
    }
  };

  const getBtcPrice = async () => {
    const rate = await getConversion();
    setConversion(rate);
  };

  /* eslint-disable */
  const verifyAllTransfers = async () => {
    const { data: batchTransfers } = await backend.rgb.verifyTransfers(
      vault.private.nostrPrv
    );
    setPendingAssetTransfers(
      batchTransfers.some((transfer) => {
        if (!transfer.status.block && transfer.iface === ifaceFungibles) {
          return true;
        }
        return false;
      })
    );
    setPendingUdaTransfers(
      batchTransfers.some((transfer) => {
        if (!transfer.status.block && transfer.iface === ifaceUdas) {
          return true;
        }
        return false;
      })
    );
  };

  const syncNonce = JSON.parse(localStorage.getItem("syncNonce") || "0");

  useEffect(() => {
    (async () => {
      const urlParams = new URLSearchParams(window.location.search);
      if (isExtension) {
        chrome.storage.local.set({ network });
      }
      const handleSyncWallet = async () => {
        if (
          !location.state ||
          !location.state.vault ||
          !location.state.wallet ||
          !location.state.lnCredentials ||
          !localStorage.getItem(`${network}Descriptor`)
        ) {
          navigate("/", { replace: true });
        } else if (urlParams.get("page")) {
          localStorage.setItem("loggedIn", "true");
          const params = window.location.href
            .split("?")[1]
            .split("#")[0]
            .split("&")
            .map((e) => e.split("=")[1]);
          const { actionsTitle, actionsDescription } =
            await chrome.storage.local.get([
              "actionsTitle",
              "actionsDescription",
            ]);
          const handleNavigation = (action) =>
            navigate("/marketplaceaction", {
              replace: true,
              state: {
                message: params[0],
                tabid: parseInt(params[1] as string, 10),
                pubkeyHash: params[2],
                id: params[3],
                uid: params[4],
                vault,
                walletDataUdas: action.data,
                hash,
                marketplaceAction: {
                  title: action.title,
                  description: action.description,
                  call: action.call,
                },
              },
            });
          switch (urlParams.get("page")) {
            case "swap_offer":
              handleNavigation({
                title: actionsTitle,
                description: actionsDescription,
                call: "swap_offer",
              });
              break;
            case "cancel_swap_offer":
              handleNavigation({
                title: actionsTitle,
                description: actionsDescription,
                call: "cancel_swap_offer",
              });
              break;
            case "cancel_swap_offer":
              handleNavigation({
                title: actionsTitle,
                description: actionsDescription,
                call: "cancel_swap_offer",
              });
              break;
            case "swap_bid":
              handleNavigation({
                title: actionsTitle,
                description: actionsDescription,
                call: "swap_bid",
              });
              break;
            case "cancel_swap_bid":
              handleNavigation({
                title: actionsTitle,
                description: actionsDescription,
                call: "cancel_swap_bid",
              });
              break;
            case "cancel_swap_bid":
              handleNavigation({
                title: actionsTitle,
                description: actionsDescription,
                call: "cancel_swap_bid",
              });
              break;
            case "get_invoice":
              handleNavigation({
                title: actionsTitle,
                description: actionsDescription,
                call: "get_invoice",
              });
              break;
            case "issue_uda":
              // eslint-disable-next-line no-case-declarations
              const result = (
                await lib.storageGet<WalletData>([udasWalletDataKey])
              )[udasWalletDataKey];
              handleNavigation({
                title: actionsTitle,
                description: actionsDescription,
                call: "issue_uda",
                data: result,
              });
              break;
            case "get_vault":
              handleNavigation({
                title: actionsTitle,
                description: actionsDescription,
                call: "get_vault",
              });
              break;
            case "get_username":
              handleNavigation({
                title: actionsTitle,
                description: actionsDescription,
                call: "get_username",
              });
              break;
            default:
              break;
          }
        } else if (Object.keys(vault).length) {
          localStorage.setItem("loggedIn", "true");
          if (
            !localStorage.getItem(`newVersionInfoPopup${VERSION}${CONTEXT}`)
          ) {
            localStorage.setItem(
              `newVersionInfoPopup${VERSION}${CONTEXT}`,
              "true"
            );
            setOpenVersionPopup(true);
          }
          const walletInfo = await retrieveWalletData();
          const lnWalletInfo = await retrieveLnWalletData();
          const transactionHistory = await retrieveActivities();

          if (walletInfo) {
            await storeWalletData(walletInfo);
          }

          console.debug("debug wallet LN", { lnWalletInfo });

          if (lnWalletInfo && typeof lnWalletInfo === "object")
            storeLnWalletData(lnWalletInfo);

          if (transactionHistory && typeof transactionHistory === "object")
            await storeActivities(transactionHistory);

          await init();
          await Promise.all([
            getAssetUtxos(),
            getAddresses(),
            getWalletData(),
            getNewAssets(),
            getBtcPrice(),
            verifyAllTransfers(),
          ]);
          if (!localStorage.getItem("showedSeedRecoveryPrompt")) {
            setOpenBanner(true);
          }
          // We are going to verify asset transactions and reload wallet and assets data waiting 1 minute after all operations are do and repeat
          const wait = 30; // in seconds
          setTimeout(async () => {
            await verifyAllTransfers();
            setTimeout(async () => {
              await Promise.all([
                getAssetUtxos(),
                getWalletData(),
                getNewAssets(),
                getBtcPrice(),
                verifyAllTransfers(),
              ]);
            }, wait * 1000);
          }, wait * 1000);
        } else {
          navigate("/", { replace: true });
        }
      };
      try {
        await handleSyncWallet();
      } catch (error) {
        console.error(error);
        setLoading(false);
        /* setOpenError(true);
        setError({
          title:
            (error as Error)?.name || "Unhandled exception in handleSyncWallet",
          message:
            (error as Error)?.message ||
            error?.toString() ||
            "Unhandled exception",
        }); */
      }
    })();
  }, [network, syncNonce]);

  useEffect(() => {
    (async () => {
      if (LNDHUBX) {
        try {
          await lnAuth();
        } catch (e) {
          console.warn("Failed initial LN auth", e);
        }
      }
    })();
  }, [LNDHUBX]);

  useEffect(() => {
    if (setMainNav) setMainNav(true);
  }, []);

  return (
    <div className="flex flex-col w-full mx-auto overflow-x-hidden text-gray-900 h-page dark:text-gray-300 dark:bg-newdarkmode-900">
      {openBanner && (
        <Banner
          setOpen={setOpenBanner}
          handleClose={() =>
            localStorage.setItem("showedSeedRecoveryPrompt", "true")
          }
        >
          If you haven&#39;t already saved your seed phrase, you can retrieve it
          in settings using your password
        </Banner>
      )}
      <div className="flex w-full h-full overflow-y-auto scrollbar dark:darkscrollbar md:mt-3">
        <div
          className={classNames(
            view === 0
              ? "min-h-[calc(100% - 56px)]"
              : "min-h-[calc((var(--vh) * 100)]",
            "h-auto min-h-full flex flex-col w-full md:px-6 max-w-4xl mx-auto"
          )}
        >
          <div ref={ref} className="flex flex-col w-full h-auto">
            <div
              className={classNames(
                process.env.CONTEXT === "MIXD Future Factory"
                  ? "hidden"
                  : "flex",
                "w-full flex-nowrap"
              )}
            >
              <div className="flex flex-col w-full pt-4 xs:pb-2 xs:py-6 md:rounded-t-2xl">
                <div className="w-full px-6 m-auto xs:w-9/12 lg:w-6/12 grid grid-cols-2 gap-3">
                  <button
                    type="button"
                    title="Copy wallet address"
                    onClick={() => {
                      navigator.clipboard.writeText(walletAddress || "");
                      setOpenCopyModal(true);
                    }}
                    className={classNames(
                      localStorage.getItem("username") ? "" : "col-span-2",
                      "rounded-full shadow-sm px-2 py-0.5 sm:py-1 flex bg-gray-300 dark:bg-yellow-500 dark:bg-opacity-15 font-thin cursor-pointer text-xs text-center w-full m-auto flex-nowrap text-yellow-500 dark:hover:bg-opacity-10 dark:text-yellow-500 min-h-[32px]"
                    )}
                    disabled={walletAddress === ""}
                  >
                    {walletAddress != "" ? (
                      <>
                        <p className="flex flex-1 w-auto mx-2 my-auto text-center truncate">
                          {walletAddress}
                        </p>
                        <Copy className="flex flex-none w-6 h-6" />{" "}
                      </>
                    ) : (
                      <p className="w-auto m-auto text-center truncate">
                        loading address...
                      </p>
                    )}
                  </button>
                  {localStorage.getItem("username") && (
                    <button
                      type="button"
                      title="Copy username"
                      onClick={() => {
                        navigator.clipboard.writeText(
                          localStorage.getItem("username") || ""
                        );
                        setOpenCopyModal(true);
                      }}
                      className="rounded-full shadow-sm px-2 py-0.5 sm:py-1 flex bg-gray-300 dark:bg-yellow-500 dark:bg-opacity-15 font-thin cursor-pointer text-xs text-center w-full m-auto flex-nowrap text-yellow-500 dark:hover:bg-opacity-10 dark:text-yellow-500"
                    >
                      <p className="w-auto mx-auto my-auto text-center truncate">
                        {localStorage.getItem("username")}
                      </p>
                      <Copy className="flex flex-none w-6 h-6" />{" "}
                    </button>
                  )}
                </div>
                <Balances
                  loadingBitcoin={loadingBitcoin}
                  walletData={walletData}
                  network={network}
                  conversion={conversion}
                  loading={loading}
                  loadingLightning={loadingLightning}
                  lnWalletData={lnWalletData}
                />
                <div className="flex order-2 w-full m-auto flex-grow-default md:order-1">
                  <div className="flex flex-row justify-center w-full m-auto flex-nowrap gap-9">
                    <IconAndLabelButton
                      className="text-base text-black bg-yellow-500 lg:text-lg"
                      onClick={() => {
                        navigate("/send", {
                          state: {
                            locationHistory: [window.location.hash],
                            type: "",
                            walletData,
                            assetUtxos,
                            vault,
                            lnCredentials,
                            hash,
                            asset: {
                              allocations: [],
                              balance: addBalance(walletData?.balance),
                              dollarBalance: (
                                addBalance(walletData?.balance) * conversion
                              ).toFixed(3),
                              id: "",
                              name: "Satoshis",
                              ticker: "Sats",
                            },
                          },
                        });
                      }}
                      label="Send"
                    >
                      <ArrowUp className="w-5 h-5 font-thin text-black" />
                    </IconAndLabelButton>
                    <IconAndLabelButton
                      className="text-base text-black bg-yellow-500 lg:text-lg"
                      onClick={() => {
                        navigate("/receive", {
                          state: {
                            walletData,
                            assetUtxos,
                            vault,
                            lnCredentials,
                            hash,
                            locationHistory: [window.location.hash],
                            tokensWalletFunded,
                            loadingVault,
                            tokensWalletAddress,
                            udasWalletAddress,
                          },
                        });
                      }}
                      label="Receive"
                    >
                      <ArrowDown className="w-5 h-5 font-thin text-black" />
                    </IconAndLabelButton>
                    <IconAndLabelButton
                      className="text-base text-black bg-yellow-500 lg:text-lg"
                      onClick={() => {
                        navigate("/send", {
                          state: {
                            locationHistory: [window.location.hash],
                            type: "",
                            walletData,
                            assetUtxos,
                            vault,
                            lnCredentials,
                            hash,
                            asset: {
                              allocations: [],
                              balance: addBalance(walletData?.balance),
                              dollarBalance: (
                                addBalance(walletData?.balance) * conversion
                              ).toFixed(3),
                              id: "",
                              name: "Satoshis",
                              ticker: "Sats",
                            },
                          },
                        });
                      }}
                      label="Swap"
                      disabled
                    >
                      <Swap className="w-5 h-5 font-thin text-black" />
                    </IconAndLabelButton>
                  </div>
                </div>
              </div>
            </div>
            <Tabs
              view={view}
              setView={setView}
              className=""
              assetsLen={assets.length}
              activitiesLen={activities?.length}
              collectionsLen={collections
                .map((collection) => collection.udas.length)
                .reduce((a, b) => a + b, 0)}
            />
          </div>
          {view === 0 && (
            <>
              {activities?.length ? (
                <div className="w-full h-auto pb-3 mx-auto xs:pb-4">
                  <div className="flex flex-col w-full h-auto mx-auto bg-transparent">
                    {activities?.map((activity) => (
                      <button
                        key={activity.id}
                        className="w-full p-3 text-gray-600 bg-gray-200 border-gray-300 border-b-1 dark:border-darkmode-600 dark:bg-darkmode-900 grid grid-cols-2 hover:bg-gray-300 dark:hover:bg-newdarkmode-800 dark:text-gray-500 disabled:pointer-events-none"
                        type="button"
                        onClick={() => {
                          navigate("/viewtransaction", {
                            state: {
                              walletData,
                              assetUtxos,
                              transaction: activity,
                              vault,
                              lnCredentials,
                              hash,
                              conversion,
                              locationHistory: [window.location.hash],
                              tokensWalletFunded,
                              tokensWalletAddress,
                              udasWalletAddress,
                            },
                          });
                        }}
                        data-test-id={TEST_IDS.wallet.activity(activity.id)}
                      >
                        <div className="flex flex-col text-left">
                          <div className="pb-2 my-auto text-xs font-thin text-black dark:text-gray-500">
                            {new Date(activity.date * 1000).toDateString()}{" "}
                            {new Date(activity.date * 1000).toLocaleTimeString(
                              undefined,
                              {
                                hour12: true,
                                hour: "numeric",
                                minute: "numeric",
                              }
                            )}
                          </div>
                          <div className="flex flex-row">
                            {activity.lightning ? (
                              <>
                                {activity.action === "sent" ? (
                                  <SendLightning className="w-12 h-12" />
                                ) : (
                                  <ReceiveLightning className="w-12 h-12" />
                                )}
                              </>
                            ) : (
                              <>
                                {activity.action === "sent" ? (
                                  <SendBitcoin className="w-12 h-12" />
                                ) : (
                                  <ReceiveBitcoin className="w-12 h-12" />
                                )}
                              </>
                            )}
                            <div className="flex flex-col my-auto ml-3 text-base font-semibold text-white">
                              <div className="my-auto ml-2 text-base font-normal text-black sm:text-lg dark:text-white">
                                {activity.action}
                              </div>
                              <div className="my-auto ml-2 text-base font-thin text-black dark:text-gray-500">
                                {activity.status}
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className="flex flex-col my-auto ml-auto text-right">
                          <span className="text-base font-normal text-gray-700 sm:text-lg dark:text-white">
                            {activity.amount <= 999_999
                              ? activity.amount.toLocaleString()
                              : getLargeNumber(activity.amount)}{" "}
                            {activity.assetType}{" "}
                          </span>
                          <span className="text-xs font-thin text-gray-700 xs:text-sm dark:text-gray-500">
                            {Number(
                              (Number(activity.amount) / 100_000_000) *
                                conversion
                            ).toLocaleString(undefined, {
                              minimumFractionDigits: 3,
                            })}{" "}
                            USD
                          </span>
                        </div>
                      </button>
                    ))}
                  </div>
                </div>
              ) : (
                <div className="flex flex-col justify-center w-full h-full m-auto flex-grow-default">
                  {loadingActivities ? (
                    <Loader className="w-12 h-12 m-auto md:h-20 md:w-20" />
                  ) : (
                    <div className="flex flex-col justify-center w-full h-full">
                      <SwapCircle className="w-12 h-12 mx-auto text-yellow-500" />
                      <p className="p-3 text-sm font-thin text-center text-gray-500 sm:text-base">
                        No activities in wallet
                      </p>
                    </div>
                  )}
                </div>
              )}
            </>
          )}
          {view === 1 && (
            <>
              {assets.length ? (
                <div className="h-auto pb-3 bg-transparent xs:pb-4 flex-grow-default">
                  <div className="flex flex-col w-full h-auto mx-auto bg-transparent">
                    {assets.map((asset) => (
                      <button
                        key={asset.contractId}
                        type="button"
                        onClick={() =>
                          navigate("/assetdetails", {
                            state: {
                              walletData,
                              assetUtxos,
                              vault,
                              asset,
                              hash,
                              pendingAssetTransfers,
                              lnCredentials,
                              funded: tokensWalletFunded,
                              tokensWalletAddress,
                              udasWalletAddress,
                              locationHistory: [window.location.hash],
                            },
                          })
                        }
                        onKeyDown={(e) =>
                          e.key === "Enter"
                            ? navigate("/assetdetails", {
                                state: {
                                  walletData,
                                  vault,
                                  pendingAssetTransfers,
                                  asset,
                                  hash,
                                  lnCredentials,
                                  funded: tokensWalletFunded,
                                  tokensWalletAddress,
                                  udasWalletAddress,
                                  locationHistory: [window.location.hash],
                                },
                              })
                            : null
                        }
                        disabled={loadingVault}
                        data-test-id={TEST_IDS.wallet.asset(asset.ticker)}
                        className="w-full p-3 text-gray-600 bg-gray-200 border-gray-300 border-b-1 dark:border-darkmode-600 dark:bg-darkmode-900 grid grid-cols-2 hover:bg-gray-300 dark:hover:bg-newdarkmode-800 dark:text-gray-500 disabled:pointer-events-none"
                      >
                        <div className="flex flex-row w-full mr-auto text-left gap-6">
                          <RgbAsset className="w-12 h-12 my-auto" />
                          <div className="flex flex-col text-base font-normal text-gray-900 truncate sm:text-lg dark:text-white">
                            <p className="mb-1">{asset.ticker}</p>
                            <p className="font-thin text-gray-500">
                              {asset.name}
                            </p>
                          </div>
                          {/* It's impossible to know the value of an asset without a market.
                            <div className="m-auto text-sm text-black  dark:text-gray-500">
                              {asset.dollarBalance
                                ? `$ ${asset.dollarBalance.toLocaleString(
                                    undefined,
                                    {
                                      minimumFractionDigits: 3,
                                    }
                                  )}USD`
                                : "value unknown"}
                                  </div> */}
                        </div>
                        <div className="flex flex-row w-full my-auto ml-auto col-span-1">
                          <div className="w-auto ml-auto text-base font-normal text-right text-gray-900 truncate flex-shrink-default dark:text-gray-300">
                            {new AssetView(asset).total()}
                          </div>
                          <ChevronLeft className="flex-initial w-6 h-6 my-auto ml-3 rotate-180" />
                        </div>
                      </button>
                    ))}
                  </div>
                </div>
              ) : (
                <div className="flex flex-col justify-center w-full h-full m-auto flex-grow-default">
                  {loadingAssets ? (
                    <Loader className="w-12 h-12 m-auto md:h-20 md:w-20" />
                  ) : (
                    <div className="flex flex-col justify-center w-full h-full">
                      <CoinCircle className="w-12 h-12 mx-auto text-yellow-500" />
                      <p className="p-3 text-sm font-thin text-center text-gray-500 sm:text-base">
                        No assets in wallet
                      </p>
                    </div>
                  )}
                </div>
              )}
            </>
          )}
          {view === 2 && (
            <>
              {collections &&
              collections.length > 0 &&
              collections[0].udas.length > 0 ? (
                <div className="w-full h-auto py-3 bg-transparent xs:py-4">
                  <div className="flex flex-col w-full h-auto gap-3 xs:gap-4 px-4 xs:px-1.5">
                    {collections.map((collection) => (
                      <Disclosure key={collection.name} as="div" className="">
                        {({ open }) => (
                          <>
                            <Disclosure.Button className="flex w-full px-3 py-1.5 text-left bg-gray-200 border-gray-300 rounded-lg shadow-sm dark:shadow-md hover:shadow-lg border-1/2 dark:border-newdarkmode-800 dark:bg-newdarkmode-800 dark:bg-opacity-50 hover:bg-gray-300 dark:hover:bg-newdarkmode-800 flex-nowrap">
                              {collection.udas[0].meta?.media.attachments
                                .length &&
                                collection.udas[0].meta?.media.attachments[0]
                                  .type === "audio/mpeg" && (
                                  <>
                                    {collection.logo ? (
                                      <img
                                        src={collection.logo}
                                        className="object-cover w-10 h-10 m-auto rounded-full"
                                        alt="unique digital asset"
                                      />
                                    ) : (
                                      <img
                                        src="/images/defaultAudioCoverArt.webp"
                                        className="object-cover w-10 h-10 m-auto rounded-full"
                                        alt="unique digital asset"
                                      />
                                    )}
                                  </>
                                )}
                              {collection.udas[0].meta?.media.attachments
                                .length &&
                                (collection.udas[0].meta?.media.attachments[0].type.includes(
                                  "image"
                                ) ||
                                  collection.udas[0].meta?.media.attachments[0]
                                    .type === "a/a") && (
                                  <img
                                    src={
                                      collection.logo
                                        ? collection.logo
                                        : collection.udas[0].meta.media
                                            ?.attachments[0].uri
                                    }
                                    className="object-cover w-10 h-10 m-auto rounded-full"
                                    alt="unique digital asset"
                                  />
                                )}
                              {collection.udas[0].meta?.media.attachments
                                .length &&
                                collection.udas[0].meta?.media.attachments[0]
                                  .type === "video/mp4" && (
                                  <>
                                    {collection.logo ? (
                                      <img
                                        src={collection.logo}
                                        className="object-cover w-10 h-10 m-auto rounded-full"
                                        alt="unique digital asset"
                                      />
                                    ) : (
                                      <img
                                        src="/images/defaultAudioCoverArt.webp"
                                        className="object-cover w-10 h-10 m-auto rounded-full"
                                        alt="unique digital asset"
                                      />
                                    )}
                                  </>
                                )}
                              <span className="m-auto ml-2 text-base font-bold truncate flex-grow-default">
                                {collection.name}
                              </span>
                              <span className="pl-3 m-auto ml-auto mr-3 text-sm font-thin text-right text-black dark:text-gray-400 whitespace-nowrap">
                                {collection.udas.length} asset
                                {collection.udas.length > 1 ? "s" : ""}
                              </span>
                              <ChevronUpIcon
                                className={`${
                                  open
                                    ? "transform rotate-180"
                                    : "transform rotate-90"
                                } w-6 h-6 text-gray-600 dark:text-gray-400 m-auto`}
                              />
                            </Disclosure.Button>
                            <Disclosure.Panel>
                              <div className="pt-1.5 grid grid-cols-3 xs:pt-4">
                                {collection.udas.map((uda) => (
                                  <button
                                    key={uda.contractId}
                                    type="button"
                                    onClick={() =>
                                      navigate("/uda", {
                                        state: {
                                          walletData,
                                          assetUtxos,
                                          pendingUdaTransfers,
                                          lnCredentials,
                                          vault,
                                          uda,
                                          hash,
                                          locationHistory: [
                                            window.location.hash,
                                          ],
                                        },
                                      })
                                    }
                                  >
                                    {uda.name}
                                    {uda.name}
                                    {uda.meta?.media.attachments.length &&
                                      uda.meta?.media.attachments[0].type ===
                                        "audio/mpeg" &&
                                      !uda.meta?.media.attachments[1] && (
                                        <img
                                          key={uda.contractId}
                                          src="/images/defaultAudioCoverArt.webp"
                                          className="object-cover w-24 h-24 m-auto my-1.5 sm:my-6 rounded-lg sm:w-44 sm:h-44"
                                          alt="unique digital asset"
                                        />
                                      )}
                                    {uda.meta?.media.attachments.length &&
                                      uda.meta?.media.attachments[0].type ===
                                        "audio/mpeg" &&
                                      uda.meta?.media.attachments[1] && (
                                        <img
                                          key={uda.contractId}
                                          src={
                                            uda.meta?.media.attachments[1].uri
                                          }
                                          className="object-cover w-24 h-24 m-auto my-1.5 sm:my-6 rounded-lg sm:w-44 sm:h-44"
                                          alt="unique digital asset"
                                        />
                                      )}
                                    {uda.meta?.media.attachments.length &&
                                      (uda.meta?.media.attachments[0].type.includes(
                                        "image"
                                      ) ||
                                        uda.meta?.media.attachments[0].type ===
                                          "a/a") && (
                                        <img
                                          key={uda.contractId}
                                          src={
                                            uda.meta?.media.attachments[0].uri
                                          }
                                          className="object-cover w-24 h-24 m-auto my-1.5 sm:my-6 rounded-lg sm:w-44 sm:h-44"
                                          alt="unique digital asset"
                                        />
                                      )}
                                    {uda.meta?.media.attachments.length &&
                                      uda.meta?.media.attachments[0].type ===
                                        "video/mp4" && (
                                        <video
                                          autoPlay
                                          loop
                                          muted
                                          playsInline
                                          crossOrigin="anonymous"
                                          controlsList="nodownload"
                                          key={uda.contractId}
                                          src={
                                            uda.meta?.media.attachments.length >
                                            0
                                              ? uda.meta?.media.attachments[0]
                                                  .uri
                                              : ""
                                          }
                                          className="object-cover w-24 h-24 m-auto my-1.5 sm:my-6 rounded-lg sm:w-44 sm:h-44"
                                        />
                                      )}
                                  </button>
                                ))}
                              </div>
                            </Disclosure.Panel>
                          </>
                        )}
                      </Disclosure>
                    ))}
                  </div>
                </div>
              ) : (
                <div className="flex flex-col justify-center w-full h-full m-auto flex-grow-default">
                  {loadingUda ? (
                    <Loader className="w-12 h-12 m-auto md:h-20 md:w-20" />
                  ) : (
                    <div className="flex flex-col justify-center w-full h-full">
                      <CoinCircle className="w-12 h-12 mx-auto text-yellow-500" />
                      <p className="p-3 text-sm font-thin text-center text-gray-500 sm:text-base">
                        No Udas in wallet
                      </p>
                    </div>
                  )}
                </div>
              )}
            </>
          )}
        </div>
      </div>
      {view !== 0 && (
        <div className="w-full mx-auto 4xl:w-4/12 3xl:w-5/12 2xl:w-6/12 xl:w-7/12 lg:w-8/12">
          <AssetFooter
            vault={vault}
            walletData={walletData}
            assetUtxos={assetUtxos}
            hash={hash}
            lnCredentials={lnCredentials}
            setOpenFundAssetsModal={setOpenFundAssetsModal}
            tokensWalletFunded={tokensWalletFunded}
            loadingVault={loadingVault}
            view={view}
            tokensWalletAddress={tokensWalletAddress}
            udasWalletAddress={udasWalletAddress}
            fundingPending={fundingPending}
          />
        </div>
      )}
      <Modal open={openFundAssetsModal} setOpen={setOpenFundAssetsModal}>
        <FundAssets
          walletBalance={addBalance(walletData?.balance)}
          tokensWalletAddress={tokensWalletAddress}
          udasWalletAddress={udasWalletAddress}
          vault={vault}
          wallet={wallet}
          lnCredentials={lnCredentials}
          hash={hash}
          setOpenFundAssetsModal={setOpenFundAssetsModal}
        />
      </Modal>
      <Modal open={openVersionPopup} setOpen={setOpenVersionPopup}>
        <ReleaseNotes version={VERSION} releaseNotes={releaseNotes} />
      </Modal>
      <CopiedToClipboard open={openCopyModal} setOpen={setOpenCopyModal} />
      <ErrorModal
        open={openError}
        setOpen={setOpenError}
        message={displayedError.message}
        title={displayedError.title}
      />
    </div>
  );
};

export default Wallet;
