/* global chrome */
import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import * as backend from "bitmask-segwit";
import { LNDHUBX, Network, init } from "bitmask-segwit/constants";

import RoundedButton from "src/Components/Buttons/RoundedButton";
import ErrorModal from "src/Components/Modals/Error";
import lib, { isExtension } from "src/lib";
import { setHash } from "src/Hooks/util";
import CheckCircle from "../Icons/CheckCircle";

const Success = ({ ...props }) => {
  const navigate = useNavigate();
  const [open, setOpen] = useState(false);
  const [error, setError] = useState({
    title: "",
    message: "",
  });

  const handleSubmit = async () => {
    try {
      const wallet = "wallet_01";
      await backend.constants.switchNetwork(Network.bitcoin);
      window.localStorage.setItem("network", Network.bitcoin);
      const encryptedDescriptors =
        window.localStorage.getItem("bitcoinDescriptor");
      if (!encryptedDescriptors) {
        throw new Error("No descriptors found");
      }
      const hash = backend.bitcoin.hashPassword(props.password);
      const vault = await backend.bitcoin.decryptWallet(
        hash,
        encryptedDescriptors
      );
      await lib.storageSet({ pubKeyHash: vault.public.xpubkh });
      await setHash(hash, vault.public.xpubkh);

      const redirectToWallet = async (refreshToken, accessToken) => {
        if (isExtension) {
          chrome.runtime.sendMessage({
            call: "reload_script_and_tabs",
          });
        }
        navigate("/wallet", {
          state: {
            wallet,
            vault,
            hash,
            lnCredentials: {
              login: vault.public.xpubkh,
              password: vault.private.xprvkh,
              refreshToken,
              accessToken,
            },
          },
        });
      };

      const handleLightningAuth = async (lndhubEndpoint: string) => {
        await init(lndhubEndpoint);

        let refresh = "";
        let token = "";
        if (LNDHUBX) {
          const tokens = await backend.lightning.auth(
            vault.public.xpubkh,
            vault.private.xprvkh
          );

          if ("error" in tokens) {
            setError({
              title: "Error Occurred on LN Wallet Authorization",
              message: tokens.error,
            });
            setOpen(true);
          } else {
            refresh = tokens.refresh;
            token = tokens.token;
            await backend.nostr.newNostrPubkey(vault.public.nostrPub, token);
          }
        }

        await redirectToWallet(refresh, token);
      };

      if (LNDHUBX) {
        if (process.env.TEST_LNDHUB_ENDPOINT)
          await handleLightningAuth("testnet");
        if (process.env.PROD_LNDHUB_ENDPOINT)
          await handleLightningAuth("bitcoin");
      } else {
        await redirectToWallet("", "");
      }
    } catch (err) {
      console.error("Error in SignIn call to get_encrypted_wallet", err);
      setError({
        title:
          (err as Error)?.name || "Unhandled exception in Success handleSubmit",
        message:
          (err as Error)?.message || err?.toString() || "Unhandled exception",
      });
      setOpen(true);
      if (err?.toString().toLowerCase().includes("invalid")) {
        console.info("Deleting descriptor, parse error");
        window.localStorage.removeItem("descriptor");
      }
    }
  };

  return (
    <div className="w-full h-auto max-w-2xl pb-10 m-auto mx-3 text-center xs:mx-6 sm:m-auto">
      <CheckCircle className="w-20 h-20 mx-auto mb-6" />

      <h1 className="text-2xl font-medium text-center text-gray-900 xs:text-3xl dark:text-gray-300 flex-grow-default">
        Congratulations!
      </h1>
      <p className="my-3 text-base font-thin text-left text-gray-500 flex-grow-default sm:text-lg">
        You have your very own BitMask wallet! Get ready for all of the
        incredible adventures to come. Get started now by opening the BitMask
        extension on your web browser. Or by using the BitMask Web Wallet at{" "}
        <a className="underline" href="https://bitmask.app">
          bitmask.app
        </a>
      </p>
      <RoundedButton
        className="w-full py-1 mx-auto mt-8 text-base font-medium text-gray-900 bg-yellow-500 sm:text-lg"
        onClick={() => handleSubmit()}
      >
        Launch
      </RoundedButton>

      <ErrorModal
        open={open}
        setOpen={setOpen}
        message={error.message}
        title={error.title}
      />
    </div>
  );
};

export default Success;
