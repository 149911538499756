/* eslint max-classes-per-file: ["error", 2] */
import { rgb } from "bitmask-segwit";
import { ContractResponse, RgbInvoiceValue } from "bitmask-segwit/rgb";
import { ifaceUdas } from "src/constants";

export class AssetView {
  private internal: ContractResponse;

  constructor(data: ContractResponse) {
    this.internal = data;
  }

  id() {
    return this.internal.contractId;
  }

  iface() {
    return this.internal.iface;
  }

  ticker() {
    return this.internal.ticker;
  }

  name() {
    return this.internal.name;
  }

  description() {
    return this.internal.description;
  }

  precision() {
    return this.internal.precision;
  }

  supply(): string {
    const { iface, supply, precision } = this.internal;

    switch (iface) {
      case ifaceUdas:
        return `1@${supply}`;
      default:
        return rgb.displayContractAmount(BigInt(supply), precision);
    }
  }

  balance(): string {
    const {
      iface,
      precision,
      balance: { uda, value },
    } = this.internal;

    const amount = BigInt(value || 0);
    switch (iface) {
      case ifaceUdas:
        return `${uda?.tokenIndex}@${uda?.fraction}`;
      default:
        return amount > 0
          ? rgb.displayContractAmount(amount, precision)
          : rgb.parseContractAmount(amount.toString(), precision);
    }
  }

  holding(): string {
    const {
      iface,
      supply,
      balance: { uda, value },
    } = this.internal;

    let total = 0;
    let amount = BigInt(0);
    switch (iface) {
      case ifaceUdas:
        amount = uda?.tokenIndex ? uda?.fraction : amount;
        total = amount === supply ? 100 : 0;
        break;
      default:
        amount = value || BigInt(0);
        total = (Number(amount) / Number(supply)) * 100;
        break;
    }

    return `${total}%`;
  }

  total(): string {
    const { ticker } = this.internal;
    const balance = this.balance();
    return `${balance} ${ticker}`;
  }

  export() {
    return this.internal;
  }
}

export class RgbInvoiceParams {
  private rgbAmount: RgbInvoiceValue;

  private internal: ContractResponse;

  constructor(data: ContractResponse) {
    this.internal = data;
    this.rgbAmount = {};
  }

  // eslint-disable-next-line
  isValid(): [boolean, any | undefined] {
    const {
      iface,
      precision,
      balance: { uda, value },
    } = this.internal;

    let amount = 0;
    switch (iface) {
      case ifaceUdas:
        amount = uda?.tokenIndex ? Number(uda?.fraction) : amount;
        break;
      default:
        amount = Number(
          rgb.displayContractAmount(BigInt(value || 0), precision)
        );
    }

    // Rule #1
    let reason;
    if (amount < this.getAmount())
      reason = {
        title: `Error creating transaction`,
        message: "Insufficient funds",
      };

    return [reason === undefined, reason];
  }

  contractId(): string {
    return this.internal.contractId;
  }

  asseType(): string {
    return this.internal.iface === ifaceUdas ? "UDA" : "token";
  }

  getAmount(): number {
    const { iface, precision } = this.internal;
    const { uda, value } = this.rgbAmount;

    let amount = 0;
    switch (iface) {
      case ifaceUdas:
        amount = uda?.tokenIndex
          ? Number(this.rgbAmount.uda?.fraction)
          : amount;
        break;
      default:
        amount = Number(
          rgb.displayContractAmount(BigInt(value || 0), precision)
        );
    }

    return amount;
  }

  setAmount(amount: RgbInvoiceValue) {
    this.rgbAmount = amount;
  }
}
