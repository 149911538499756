/* eslint-disable no-unused-vars */
/* eslint-disable @typescript-eslint/no-explicit-any */
/* global chrome */
import React, { useState, useEffect } from "react";
import { useNavigate, useLocation, Link } from "react-router-dom";
import * as backend from "bitmask-segwit";

import TextArea from "src/Components/Inputs/TextArea";
import ImportAssetTabs from "src/Components/Tabs/ImportAssetTabs";
import Search from "src/Components/Inputs/Search";
import PageWrapper from "src/Components/Layout/Wrappers/PageWrapper";
import ErrorModal from "src/Components/Modals/Error";
import lib, { isExtension } from "src/lib";
import { Asset, Location } from "src/types";
import { classNames } from "src/Hooks/util";
import Loader from "src/Components/Loaders/Loader";

const ImportAsset = () => {
  const navigate = useNavigate();
  const location = useLocation() as Location;
  const { walletData, vault, hash, lnCredentials } = location.state;
  const [view, setView] = useState(0);
  const [id, setId] = useState("");
  const [search, setSearch] = useState("");
  const [contract, setContract] = useState("");
  const [searchResults, setSearchResults] = useState([] as Asset[]);
  const [open, setOpen] = useState(false);
  const [error, setError] = useState({
    title: "",
    message: "",
  });
  const [disabled, setDisabled] = useState({
    rgb: true, // TODO: rgb asset id import must be restored
    contract: false,
  });
  const [loading, setLoading] = useState(false);
  const [loadingAssets, setLoadingAssets] = useState(false);
  const [assets, setAssets] = useState<any[]>([]);
  const [amount, setAmount] = useState(0);
  const network = window.localStorage.getItem("network");

  const getAssets = async (currentPage) => {
    setLoadingAssets(true);
    const assetSet = await fetch(
      `${isExtension ? "https://beta.bitmask.app" : ""}/api/paginate`,
      {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          page: currentPage,
          size: "all",
          searchKeyword: search,
        }),
      }
    );
    const resp = await assetSet.json();
    if (resp.data) setLoadingAssets(false);
    setAssets(resp.data);
    setAmount(resp.amount);
  };

  useEffect(() => {
    const loadAssets = async () => {
      if (localStorage.getItem(`${network}Descriptor`)) {
        getAssets(1);
      } else {
        getAssets(1);
      }
    };
    if (search !== "") {
      loadAssets();
    }
  }, [search]);

  useEffect(() => {
    if (location.state.contract) setContract(location.state.contract);
    (async () => {
      const key = "listAssets";
      const result = await lib.storageGet<Asset[]>([key]);

      if (result[key]) {
        const futureSearchResults = result[key];
        setSearchResults(futureSearchResults);
      }
    })();
  }, []);

  useEffect(() => {
    if (id !== "") {
      setDisabled({ rgb: false, contract: true });
    } else if (contract !== "") {
      setDisabled({ contract: false, rgb: true });
    } else if (id === "" && contract === "") {
      setDisabled({ contract: false, rgb: false });
    }
  }, [id, contract]);

  const handleSubmit = async (
    contractId: string | null,
    contractGenesis: string | null
  ) => {
    let assetData: backend.rgb.ContractResponse;
    try {
      if (contractId) {
        if (contractId.startsWith("rgb:")) {
          setLoading(true);
          const dbAsset = await fetch(
            `${
              isExtension ? "https://beta.bitmask.app" : ""
            }/api/getasset/${contractId}`
          );
          const assetJson = await dbAsset.json();

          assetData = await backend.rgb.importContract(
            vault.private.nostrPrv,
            assetJson.contractGenesis
          );
        } else {
          setLoading(false);
          setError({
            title: `Error importing asset by ID`,
            message: "Server Error: Invalid RGB ID format",
          });
          setOpen(true);
          return;
        }
      } else if (contract !== "" || contractGenesis) {
        setLoading(true);
        assetData = await backend.rgb.importContract(
          vault.private.nostrPrv,
          contractGenesis || contract
        );
      } else {
        setLoading(false);
        setError({
          title: `Error importing asset by contract`,
          message: "Error: No asset provided",
        });
        setOpen(true);
        return;
      }

      if (assetData) {
        setLoading(true);
        navigate("/importdescription", {
          state: {
            walletData,
            vault,
            lnCredentials,
            hash,
            asset: assetData,
            source: { view: view === 0 ? "Custom" : "Search", param: search },
          },
        });
      } else {
        console.error("No assetData found");
      }
    } catch (assetError: any) {
      console.error("asset error in ContractResponse", assetError);
      if (assetError.toString().includes("Server error: Incorrect rgb id")) {
        setLoading(false);
        setError({
          title: `Error importing asset`,
          message: "Server error: Invalid RGB ID",
        });
        setOpen(true);
      } else if (assetError.toString().includes("rgbc1")) {
        setLoading(false);
        setError({
          title: "Error importing asset",
          message: "Server error: Invalid RGB contract",
        });
        setOpen(true);
      } else {
        console.error("UNHANDLED ERROR in ContractResponse");
      }
    }
  };

  const addId = async (arg) => {
    setId(arg);
  };

  const tabs = [{ title: "Custom" }, { title: "Search" }];

  return (
    <PageWrapper
      title="Import Asset"
      handlePageBack={() =>
        navigate("/wallet", {
          state: { wallet: walletData.name, vault, hash, lnCredentials },
        })
      }
      handleCancel={() =>
        navigate("/wallet", {
          state: { wallet: walletData.name, vault, hash, lnCredentials },
        })
      }
      handleSubmit={() => handleSubmit(id, null)}
      loading={loading}
    >
      <ImportAssetTabs view={view} setView={setView} tabs={tabs} />
      {view === 0 && (
        <div className="h-64 mt-4 text-gray-800 dark:text-gray-500">
          <TextArea
            className="w-full p-2"
            placeholder="Enter asset ID here"
            rows={3}
            onChange={(e) => setId(e.target.value)}
            disabled={disabled.rgb}
          />
          <p className="my-4 text-sm font-normal text-center sm:text-base flex-grow-default">
            or
          </p>
          <TextArea
            className="w-full p-2"
            placeholder="Enter contract"
            rows={3}
            value={contract}
            onChange={(e) => setContract(e.target.value)}
            disabled={disabled.contract}
          />
        </div>
      )}
      {view === 1 && (
        <div className="w-full h-64 my-auto mt-4 dark:border-newdarkmode-600">
          <div className="flex flex-col w-full sm:flex-row">
            <div className="w-auto my-auto flex-grow-default sm:mr-3">
              <Search
                className="w-full p-2 m-auto"
                search={search}
                setSearch={setSearch}
              />
            </div>
            <Link
              to="/registry"
              className="flex-grow-0 hidden w-auto m-auto mt-1 text-sm font-thin text-black underline sm:mt-auto md:text-base dark:text-white"
            >
              View asset registry
            </Link>
          </div>
          <div className="flex flex-col h-full overflow-y-auto sm:mt-3 gap-1 scrollbar dark:darkscrollbar">
            {loadingAssets ? (
              <Loader className="w-20 h-20 m-auto" />
            ) : (
              <>
                {assets.length ? (
                  <>
                    {assets.map((asset) => (
                      <button
                        key={asset.contractId}
                        type="button"
                        className="rounded-md bg-gray-300 dark:bg-darkmode-900 mr-1.5"
                        onClick={() => {
                          handleSubmit(null, asset.contractGenesis);
                        }}
                      >
                        <div className="px-6 py-3 text-base font-semibold text-left text-gray-900 cursor-pointer dark:text-gray-300 dark:hover:text-yellow-500 hover:text-gray-600">
                          <div className="flex items-center gap-x-3">
                            <h3 className="flex-auto text-sm font-semibold text-black truncate leading-6 dark:text-white">
                              {asset.ticker}
                            </h3>
                          </div>
                          <p className="mt-1.5 truncate text-sm text-gray-700 dark:text-gray-500">
                            Created by{" "}
                            <span className="text-black dark:text-gray-400">
                              {asset.username}
                            </span>
                          </p>{" "}
                        </div>
                      </button>
                    ))}
                  </>
                ) : (
                  <p className="m-auto text-base text-center text-gray-600 lg:text-lg dark:text-gray-300">
                    No results at the moment
                  </p>
                )}
              </>
            )}
          </div>
        </div>
      )}
      <ErrorModal
        open={open}
        setOpen={setOpen}
        message={error.message}
        title={error.title}
      />
    </PageWrapper>
  );
};

export default ImportAsset;
