import React, { useContext, useLayoutEffect, useState } from "react";
import { Outlet } from "react-router-dom";

import SecondaryNav from "src/Components/Navbar/SecondaryNav";
import { MainNavContext } from "src/Hooks/MainNavContext";
import useWindowSize from "src/Hooks/useWindowSize";

const Layout = () => {
  const size = useWindowSize();
  const [height, setHeight] = useState(0);
  const { mainNav } = useContext(MainNavContext);

  useLayoutEffect(() => {
    setHeight(size.height - 56);
  }, [mainNav, size]);

  return (
    <div className="sticky top-0 flex flex-col justify-center h-full min-h-full overflow-hidden">
      <SecondaryNav
        className="text-white bg-darkmode-900"
        menuItems={[
          { name: "Home", route: "/" },
          { name: "FAQ", route: "/faq" },
          { name: "Registry", route: "/registry" },
          { name: "Metrics", route: "/metrics" },
          {
            name: "GitHub",
            link: "https://github.com/diba-io/bitmask-segwit/",
          },
        ]}
      />
      <div
        style={{
          // remove navbar height from page height
          height,
          minHeight: `${mainNav ? "551px" : height}`,
        }}
        className="w-full my-auto overflow-y-auto dark:bg-darkmode-900 scrollbar dark:darkscrollbar"
      >
        <div className="flex flex-col justify-center h-auto min-h-full sm:pt-0">
          <Outlet />
        </div>
      </div>
    </div>
  );
};

export default Layout;
